<template>
  <transition enter-active-class="animated-400 fadeInRight"
              leave-active-class="animated-400 fadeOutRight">
    <b-row class="container-popup" v-if="show">
      <el-button circle class="btn-close" @click="Fechar()">
        <i class="el-icon-close"></i>
      </el-button>
      <b-col :span="24">
        <h3 class="lb-titulo">Oferta Exclusiva! 🤑</h3>
        <p class="lb-descricao">
          Não perca essa super oportunidade.
          Atualize o seu plano agora com 20% DE DESCONTO NO PLANO ANUAL e tenha acesso a funcionalidades profissionais, como domínio próprio (suaempresa.com), venda de passagens com milhas e muito mais!
        </p>
      </b-col>
      <b-col :span="24">
        <a href="#/MeuPlano" @click="Fechar()">
          <el-button class="primary-background-color" type="primary" size="big">ATUALIZAR AGORA! 🚀</el-button>
        </a>
      </b-col>
    </b-row>
  </transition>
</template>

<script>
export default {
  name: "PopUpNotificacao",
  props: ['show'],
  methods: {
    Fechar() {
      this.$emit('close')
    }
  },
}
</script>

<style scoped>
.container-popup {
  position: fixed;
  right: 30px;
  top: 75px;
  z-index: 9999;
  background: #fff;
  border: solid 1px #e2e2e2;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 2px 3px 12px #4c4c4c;
  max-width: 350px;
  display: block;
}

.lb-titulo {
  font-weight: 700;
  font-size: 18px;
  color: #303133;
  margin: 0;
  margin-bottom: 10px;
}

.btn-close {
  position: absolute;
  right: 0px;
  top: 0px;
  border: 0px;
  box-shadow: none;
  z-index: 999999;
  background: #e2e2e2;
}

.btn-close {
  font-weight: 900;
}

.lb-descricao {
  color: #797979 !important;
}
</style>
