<template>
  <el-dialog class="text-left"
             :width="LarguraModal"
             :visible.sync="dialogVisible">
    <div slot="title">
      <h5>Selecione uma conta</h5>
    </div>
    <el-input placeholder="Filtrar" v-model="filtroParceiros" @keyup.enter.native="FiltrarParceiro">
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input>
    <div class="container-list margin-top-10"
         v-loading="carregando">
      <el-row :key="p.Id" v-for="p in parceiros">
        <div @click="LogarComo(p)">
          <el-col class="col-parceiro-item">
            <span>{{ p.Id }} - {{ p.Nome }}</span>
            <span class="float-right">
                            <i class="el-icon-caret-right"></i>
                        </span>
          </el-col>
        </div>
      </el-row>
    </div>
    <el-row slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">Cancelar</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import ParceiroService from "@/services/ParceiroService";
import LoginService from "@/services/LoginService";

export default {
  name: "ModalLogarComo",
  data() {
    return {
      carregando: false,
      filtroParceiros: "",
      dialogVisible: false,
      parceiros: []
    }
  },
  created() {
    this.parceiroService = new ParceiroService(this)
    this.loginService = new LoginService(this)
  },
  methods: {
    Show() {
      this.dialogVisible = true;
      this.ListarParceiros();
    },
    FiltrarParceiro() {
      if (this.filtroParceiros) {
        this.ListarParceiros()
      }
    },
    ListarParceiros() {
      this.carregando = true;
      let filtro = null;
      if (this.filtroParceiros) {
        filtro = {
          Nome: this.filtroParceiros,
          PageSize: 10,
        }
      }
      this.parceiroService.Listar(filtro, (res) => {
        this.parceiros = res.Data;
        this.carregando = false;
      });
    },
    LogarComo(agencia) {
      this.carregando = true;
      this.loginService.LogarComo(agencia.Id, this.CallbackLogarComo);
    },
    CallbackLogarComo(res) {
      if (res.access_token) {
        localStorage.clear();
        sessionStorage.clear();
        this.loginService.SalvarToken(res.access_token, res.expires_in / 60)
        window.location.reload();
      }
    },
  },
  computed: {
    LarguraModal() {
      if (window.innerWidth < 700) return "100%";
      else if (window.innerWidth < 848) return "80%";
      else return "600px";
    },
  }
}
</script>

<style scoped>

.col-parceiro-item {
  border: 1px solid #e3e3e3;
  padding: 12px;
  margin-bottom: 5px;
  font-weight: 500;
  cursor: pointer;
}

.col-parceiro-item:hover {
  border: solid 1px #409eff;
  color: #409eff;
}

.container-list {
  min-height: 200px !important;
}
</style>