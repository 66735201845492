<template>
  <div v-bind:class="{'form-container': !fullWidth, 'padding-full-width': fullWidth}">
    <div class="header">
      <content-header-modal
          id="headerTop"
          :fn-close="fnClose"
          :label="label"
          :sub-label="subLabel"
          :size="size"
          :hide-back="hideBack"
          :hide-default-buttons="hideDefaultButtons"
          :l-items-dropdown="lItemsDropdown"
          :msg-loading-dropdown="msgLoadingDropdown"
          @guardar="$emit('guardar')">
        <slot></slot>
      </content-header-modal>
      <transition enter-active-class="animated-400 slideInDown" leave-active-class="animated-200 slideOutUp">
        <div id="header-fixed" class="float-header" v-show="!headerTopVisible">
          <content-header-modal
              :fn-close="fnClose"
              :label="label"
              :sub-label="subLabel"
              :show-check="showCheck"
              :size="size"
              :hide-back="hideBack"
              :hide-default-buttons="hideDefaultButtons"
              :l-items-dropdown="lItemsDropdown"
              :msg-loading-dropdown="msgLoadingDropdown"
              @guardar="$emit('guardar')">
            <slot></slot>
          </content-header-modal>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import Ripple from "vue-ripple-directive";
import ClienteService from "../../../services/ClienteService";
import UtilService from "../../../services/UtilService";
import ContentHeaderModal from "@/components/Shared/HeaderModal/ContentHeaderModal";

export default {
  components: {ContentHeaderModal},
  props: [
    "fnClose",
    "label",
    "subLabel",
    "showCheck",
    "size",
    "fullWidth",
    "hideDefaultButtons",
    "hideBack",
    "lItemsDropdown",
    "msgLoadingDropdown"
  ],
  directives: {
    Ripple,
  },
  data() {
    return {
      labelTemp: 'Adicionar',
      headerTopVisible: true
    }
  },
  created() {
    this.util = new UtilService(this);
    this.clienteService = new ClienteService(this);
  },
  mounted() {
    this.WatchHeaderTop()
  },
  methods: {
    WatchHeaderTop() {
      let containerModal = document.getElementsByClassName('container-modal-fullscreen')
      if (containerModal.length) {
        containerModal[0].addEventListener('scroll', this.CheckVisible);
      }
    },
    CheckVisible() {
      var element = document.getElementById('headerTop');
      var position = element.getBoundingClientRect();
      let visible = false;
      // checking whether fully visible
      if (position.top >= 0 && position.bottom <= window.innerHeight) {
        visible = true;
      }
      // checking for partial visibility
      else if (position.top < window.innerHeight && position.bottom >= 0) {
        visible = true;
      }

      if (visible)
        this.SetWidthHeaderFloat()

      this.headerTopVisible = visible
    },
    SetWidthHeaderFloat() {
      let width = document.querySelector('#headerTop').clientWidth
      document.getElementById("header-fixed").style.width = width + 'px';
    }
  },
};
</script>
<style scoped>
.float-header {
  position: absolute;
  top: 10px;
  z-index: 999;
  background: #fff;
  -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 23%);
  padding: 5px 15px;
  border-radius: 4px;
  height: 60px;
}

.padding-full-width {
  padding: 0px 12px;
}
</style>
