import axios from 'axios'
import UtilService from './UtilService'
import LoginService from './LoginService'

export default class PostService {
    constructor(source, needToken, host) {
        this.source = source
        this.util = new UtilService(source)
        this.host = host ?? process.env.VUE_APP_URL_API
        this.client = axios
        this.client.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
        if (needToken) {
            let loginService = new LoginService()
            let token = loginService.PegarToken()
            this.client.defaults.headers.post['Authorization'] = 'Bearer ' + loginService.PegarToken()
            this.client.defaults.headers.get['Authorization'] = 'Bearer ' + token
        }
        if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
            this.client.defaults.headers.post['origem'] = 'android_device_painel'
        } else if (/iPad|iPhone/.test(navigator.platform)) {
            this.client.defaults.headers.post['origem'] = 'iphone_device_painel'
        }
    }

    PostFormData(url, formData, callback, callbackErro) {
        url = url.startsWith('http') ? url : (this.host + url)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        axios.post(url, formData, config)
            .then(response => {
                if (!url.includes(process.env.VUE_APP_URL_AUTHENTICATION))
                    this._TratarRetornoSucesso(response, callback, callbackErro, false)
                else
                    callback(response.data)
            })
            .catch(erroPost => {
                this._TratarErro(erroPost, callbackErro, url)
            })
    }

    Post(url, params, callback, callbackErro, hideLoading, index = 0) {
        let urlPost = this.util.UrlCombine(this.host, url)
        this.client.post(urlPost, params).then((response) => {
            this._TratarRetornoSucesso(response, callback, callbackErro, url === '/Token', index)
        }).catch(erroPost => {
            this._TratarErro(erroPost, callbackErro, url)
        })
    }

    _TratarErro(erro, callbackErro, url) {
        if (callbackErro) {
            callbackErro(erro)
        } else {
            this._showMessageErro(erro, url)
        }
    }

    _TratarRetornoSucesso(response, callback, callbackErro, isPostToken, index = 0) {
        if (isPostToken) {
            callback(response.data, index)
        } else if (response.data.Success) {
            callback(response.data, index)
        } else if (callbackErro) {
            callbackErro(response.data)
        } else {
            this.source.$message.error(response.data.Erro.Message)
        }
    }

    _showMessageErro(erro, url) {
        let msg = '--'
        if (erro.response && erro.response.statusText) {
            if (erro.response.status === 401) {
                msg = '--'
                localStorage.clear()
            } else msg = erro.response.statusText
        } else if (erro.response && erro.response.data && erro.response.data.error_description) {
            msg = erro.response.data.error_description
        }
        if (msg !== '--') this.source.$message.error(msg)
        console.log('ERRO: ' + url + erro)
    }
}
