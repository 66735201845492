<template>
  <div>
    <el-dropdown class="i-icon" trigger="click">
      <span class="el-dropdown-link">
        <i class="el-icon-circle-plus"></i>
      </span>
      <el-dropdown-menu slot="dropdown" class="botao-add">
        <router-link to="/cliente/-1">
          <el-dropdown-item class="dropdown-item-flex">
            <div class="item-name"><i class="el-icon-user"></i>Cliente</div>
            <div class="row-atalhos">
              <el-tag type="info" size="mini">Alt</el-tag>
              <el-tag type="info" size="mini">C</el-tag>
            </div>
          </el-dropdown-item>
        </router-link>
        <router-link to="/Negociacao/-1">
          <el-dropdown-item class="dropdown-item-flex">
            <div class="item-name"><i class="el-icon-money"></i>Negociação</div>
            <div class="row-atalhos">
              <el-tag type="info" size="mini">Alt</el-tag>
              <el-tag type="info" size="mini">N</el-tag>
            </div>
          </el-dropdown-item>
        </router-link>
        <router-link to="/Pages/-1">
          <el-dropdown-item class="dropdown-item-flex">
            <div class="item-name"><i class="el-icon-document-add"></i>Página</div>
            <div class="row-atalhos">
              <el-tag type="info" size="mini">Alt</el-tag>
              <el-tag type="info" size="mini">P</el-tag>
            </div>
          </el-dropdown-item>
        </router-link>
        <router-link to="/Conteudos/-1">
          <el-dropdown-item class="dropdown-item-flex">
            <div class="item-name"><i class="el-icon-tickets"></i>Post</div>
            <div class="row-atalhos">
              <el-tag type="info" size="mini">Alt</el-tag>
              <el-tag type="info" size="mini">O</el-tag>
            </div>
          </el-dropdown-item>
        </router-link>
        <router-link to="/Produto/-1">
          <el-dropdown-item class="dropdown-item-flex">
            <div class="item-name"><i class="el-icon-guide"></i>Produto</div>
            <div class="row-atalhos">
              <el-tag type="info" size="mini">Alt</el-tag>
              <el-tag type="info" size="mini">R</el-tag>
            </div>
          </el-dropdown-item>
        </router-link>
        <router-link to="/Financeiro/contas-a-receber/00000000-0000-0000-0000-000000000000">
          <el-dropdown-item class="dropdown-item-flex">
            <div class="item-name"><i class="el-icon-download"></i>Receita</div>
            <div class="row-atalhos">
              <el-tag type="info" size="mini">Alt</el-tag>
              <el-tag type="info" size="mini">E</el-tag>
            </div>
          </el-dropdown-item>
        </router-link>
        <router-link to="/Financeiro/contas-a-pagar/00000000-0000-0000-0000-000000000000">
          <el-dropdown-item class="dropdown-item-flex">
            <div class="item-name"><i class="el-icon-upload2"></i>Despesa</div>
            <div class="row-atalhos">
              <el-tag type="info" size="mini">Alt</el-tag>
              <el-tag type="info" size="mini">D</el-tag>
            </div>
          </el-dropdown-item>
        </router-link>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener('keydown', this.handleKeyPress);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyPress);
  },
  methods: {
    handleKeyPress(e) {
      if (e.altKey) {
        switch (e.key.toLowerCase()) {
          case 'c': // Cliente
            this.$router.push('/cliente/-1');
            break;
          case 'n': // Negociação
            this.$router.push('/Negociacao/-1');
            break;
          case 'p': // Página
            this.$router.push('/Pages/-1');
            break;
          case 'o': // Post (Conteúdo)
            this.$router.push('/Conteudos/-1');
            break;
          case 'e': // Produto
            this.$router.push('/Produto/-1');
            break;
          case 'r': // Receita
            this.$router.push('/Financeiro/contas-a-receber/00000000-0000-0000-0000-000000000000');
            break;
          case 'd': // Despesa
            this.$router.push('/Financeiro/contas-a-pagar/00000000-0000-0000-0000-000000000000');
            break;
          default:
            // Não faz nada se a combinação de teclas não estiver mapeada
            break;
        }
      }
    }
  }
}
</script>


<style scoped>
.botao-add {
  min-width: 130px;
}

.el-icon-circle-plus {
  top: 4px;
  position: relative;
}

.icon-top i {
  font-size: 20px;
}

.dropdown-item-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-name {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.row-atalhos .el-tag {
  margin-left: 4px;
  /* Espaçamento à direita de cada tag */
}

.row-atalhos {
  text-align: left;
}
</style>
