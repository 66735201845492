import axios from 'axios'
import UtilService from './UtilService'

export default class CepService {
	constructor(source) {
		this.source = source
		this.util = new UtilService(source)
	}

	BuscarCep(cep, callback, callbackErro) {
		let url = 'https://viacep.com.br/ws/' + cep + '/json/'
		axios.get(url, {
			transformRequest: (data, headers) => {
				delete headers.get['Authorization'];
				return data;
			},
		}).then(function (result) {
			let res = result.data
			callback(res)
		}).catch(error => {
			alert('Erro Dne')
			console.log(error)
			if (callbackErro)
				callbackErro()
		})
	}
}
