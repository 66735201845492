<template>
  <el-dialog
      title="Atenção"
      :visible.sync="show"
      width="30%"
      center>
    <p class="text-center">Deseja submeter as alteraçoes na lista de IPs?</p>
    <span slot="footer" class="dialog-footer">
        <el-button @click="confirm(false)">Cancelar</el-button>
        <el-button type="primary" @click="confirm(true)">Confirmar</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "DialogEditSubnet",
  data() {
    return {
      show: false
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    confirm(val) {
      this.$emit('confirm', val);
      this.show = false;
    }
  },
  computed: {}
};
</script>

<style scoped>
</style>
