<template>
  <div class="show-starting-992">
    <div class="desktop container-input">
      <el-input
          type="search"
          placeholder="Pesquisar..."
          v-model="resultSearch"
          :class="visibleSearchUpdated ? 'full-width' : 'standart-width focusOut'"
          class="search-input"
          size="large"
          @blur="visibleSearch = false"
          @focus="visibleSearch = true"
          @keydown.esc="visibleSearch = false"
          @input="updateSearch"
          ref="search"
          autocomplete="off"
          prefix-icon="el-icon-search"
      >
      </el-input>
    </div>
    <el-card
        v-if="resultSearch.length > 0 && visibleSearch"
        class="search-content desktop"
        :body-style="{padding:'0px', minWidth:'550px', maxHeight: '500px', overflow:'auto'}"
    >
      <div v-if="results.length > 0 && visibleSearch" slot="header" class="clearfix">
        <span>Selecione a opção desejada</span>
      </div>
      <div>
        <ul class="ul-container" v-infinite-scroll="load">
          <li>
            <a v-for="({item}, index) in results" :key="index"
               class="il-container"
               :href='item.path'
               @mousedown.prevent="visibleSearch = true"
               @click="closeSearch"
            >
              <i class="el-icon-right"></i>
              <div class="item-content">
                <h6>{{ item.title }}</h6>
                <p>{{ item.description }}</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <el-divider v-if="results.length === 0"></el-divider>
      <div class="resultNone" v-if="results.length === 0">
        <span>Sem resultados para <strong>{{ resultSearch }}</strong></span>
      </div>
    </el-card>
    <div class="mobile">
      <div class="">
        <i @click="visibleMobileSearch = !visibleMobileSearch" slot="prefix"
           class="search-mobile el-input__icon el-icon-search"></i>
      </div>
      <el-card
          v-if="visibleMobileSearch"
          class="search-content"
          :body-style="{padding:'0px', maxHeight: '500px', overflow:'auto'}"
          @blur="visibleMobileSearch = false"
      >
        <div slot="header" class="clearfix">
          <el-input
              type="search"
              placeholder="Pesquisar"
              v-model="resultSearch"
              class="search-input"
              size="large"
              @focus="visibleMobileSearch = true"
              @input="updateSearch"
              ref="search"
          >
            <i slot="prefix" class="icon-style el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div>
          <ul v-if="results.length > 0" class="ul-container" v-infinite-scroll="load">
            <li>
              <a v-for="({item}, index) in results" :key="index"
                 class="il-container"
                 :href='item.path'
                 @mousedown.prevent="visibleMobileSearch = true"
                 @click="closeSearch"
              >
                <i class="el-icon-right"></i>
                <div class="item-content">
                  <h6>{{ item.title }}</h6>
                  <p>{{ item.description }}</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="resultNone" v-if="results.length === 0">
          <span>Sem resultados para <strong>{{ resultSearch }}</strong></span>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'
import listJson from './list.json'

export default {
  name: 'SearchField',
  components: {},
  computed: {
    visibleSearchUpdated() {
      return this.visibleSearch
    }
  },
  data() {
    return {
      countScrool: 0,
      list: [],
      results: [],
      options: {
        includeScore: true,
        includeMatches: true,
        useExtendedSearch: false,
        threshold: 0.0,
        maxPatternLength: 32,
        keys: [
          "title",
          "description",
          'tags'
        ]
      },
      resultSearch: '',
      visibleSearch: false,
      visibleMobileSearch: false
    }
  },
  created() {
    this.list = listJson
  },
  methods: {
    closeSearch() {
      this.visibleMobileSearch = false
      this.visibleSearch = false
      this.resultSearch = ''
    },
    load() {
      return this.count += 2
    },
    // inputReset() {
    //   this.resultSearch = ''
    //   this.results = ''
    // },
    softReset() {
      this.visibleSearch = true
    },
    updateSearch() {
      this.fuse = new Fuse(this.list, this.options)
      this.results = this.fuse.search(this.resultSearch)
    }
  }
}
</script>

<style scoped>

.standart-width {
  width: 50%;
  transition: all 300ms ease-in-out;
}

.full-width {
  width: 100%;
  transition: all 300ms ease-in-out;
}

.desktop {
  display: flex;
}

.mobile {
  display: none;
}

.search-mobile {
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}

.container-input {
  width: 100%;
  position: relative;
  height: 40px;
  display: flex;
  justify-content: flex-start;
}

.icon-style {
  color: #000;
  font-size: 1.1rem;
  cursor: pointer;
}

.search-input {
  position: relative;
}

.search-input:focus {
  border: 2px solid blue;
}

.search-content {
  flex-direction: column;
  position: absolute;
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 2px 3px 3px rgba(0, 0, 0, 0.1);
  z-index: 9999999;
}

.ul-container {
  list-style: none;
  padding: 0px;
  margin-bottom: 5px;
}

.il-container {
  display: flex;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  padding: 10px;
  color: #000;
}

.il-container:hover {
  background: #fff;
}

.il-container > i {
  min-width: 10%;
  text-align: start;
}

.item-content {
  text-align: start;
}

.item-content > h6 {
  margin-bottom: 2px;
}

.item-content > p {
  margin: 0px;
  color: grey;
  font-size: 12px;
}

.resultNone {
  padding: 10px;
}

div.el-input.el-input--large.el-input--prefix.standart-width > input.el-input__inner {
  background-color: red !important;
}
</style>
