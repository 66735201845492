<template>
  <div class="text-right" v-bind:class="{'margin-top-20' : !IsCompact}">
    <el-dropdown size="small"
                 v-bind:split-button="!IsCompact && lDropdown.length > 1"
                 @command="handleCommand" trigger="click"
                 type="primary">
      <el-button v-if="IsCompact" size="small" type="primary" icon="el-icon-more-outline" :loading="IsLoading"/>
      <el-button v-else-if="lDropdown.length === 1" size="small" type="primary" :icon="lDropdown[0].icon"
                 @click="lDropdown[0].fn()" :loading="IsLoading">
        <span v-if="!msgLoading">
        {{ lDropdown[0].text }}
        </span>
        <span v-else>
          {{msgLoading}}
        </span>
      </el-button>
      <span @click="lDropdown[0].fn()" v-else>
        <i :class="lDropdown[0].icon"></i> {{ lDropdown[0].text }}
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
            v-for="i in ListItens"
            :key="i.value"
            v-bind:disabled="i.disabled"
            :command="i">
          <i v-if="i.icon" :class="i.icon"/>
          {{ i.text }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "DropdownHeaderModal",
  props: ['lDropdown', 'msgLoading'],
  methods: {
    handleCommand(c) {
      c.fn();
    }
  },
  computed: {
    ListItens() {
      let retorno = [];
      for (let counter = this.IsCompact ? 0 : 1; counter < this.lDropdown.length; counter++)
        retorno.push(this.lDropdown[counter])
      return retorno;
    },
    IsCompact() {
      return this.$screen.sm || this.$screen.xs
    },
    IsLoading() {
      return this.msgLoading !== ''
    }
  },
}
</script>

<style scoped>

</style>