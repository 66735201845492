<template>
    <header-modal
        v-show="ShowHeader"
        :fn-close="fnClose"
        :label="Titulo"
        :sub-label="Description"
        :hide-back="hideBack"
        :hide-default-buttons="!ShowDefaultButtons"
        @guardar="Save"
        :l-items-dropdown="dropdown.itens"
        :msg-loading-dropdown="dropdown.msgLoading" />
</template>

<script>
import HeaderModal from "@/components/Shared/HeaderModal/HeaderModal.vue";

export default {
  name: "HeaderPage",
  components: {HeaderModal},
  data() {
    return {
      dropdown: {
        parent: '',
        itens: [],
        msgLoading: ''
      },
      fnSave: {
        parent: '',
        fn: null
      },
      parentHeader: '',
      hideBack: true,
      parentFnSave: ''
    }
  },
  created() {
    this.VerifyRoute();
  },
  watch: {
    $route() {
      this.VerifyRoute();
      this.dropdown.msgLoading = '';
    }
  },
  methods: {
    VerifyRoute() {
      if (this.dropdown.parent !== this.$route.name) {
        this.dropdown.parent = '';
        this.dropdown.itens = [];
      }

      if (this.fnSave.parent !== this.$route.name) {
        this.fnSave.parent = '';
        this.fnSave.fn = null;
      }

      this.hideBack = !this.$route.meta.showBack;
    },
    fnClose() {
      this.$router.go(-1);
    },
    Save() {
      if (this.fnSave.fn)
        this.fnSave.fn();
    }
  },
  computed: {
    Titulo() {
      return this.$route.name;
    },
    Description() {
      let retorno = '';
      if (this.$route.meta)
        retorno = this.$route.meta.description
      return retorno;
    },
    ShowHeader() {
      return !this.$route.meta.hideHeader
    },
    ShowDefaultButtons() {
      return this.fnSave.fn != null
    }
  },
}
</script>

<style scoped>

</style>