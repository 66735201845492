<template>
  <el-row class="card-container" v-bind:class="{ 'no-padding-bottom': noPaddingBottom }"
    v-bind:style="{ 'height': vHeight }">
    <div class="card-title" v-if="!hideHeader">
      <div class="div-title">
        <h2 class="title" v-bind:class="{ 'is-link': titleUrl }" @click="GoUrl()">
          {{ title }}&nbsp;&nbsp;<i class="el-icon-link" v-if="titleUrl"></i>
        </h2>
        <span v-if="subtitle" class="sub-title" v-html="subtitle"></span>
      </div>
      <div class="slot-actions">
        <slot name="header-action">
        </slot>
      </div>
    </div>
    <slot></slot>
  </el-row>
</template>

<script>
export default {
  name: "Card",
  props: ['title', 'subtitle', 'titleUrl', 'noPaddingBottom', 'height', 'hideHeader'],
  data() {
    return {
      vHeight: 'auto'
    }
  },
  created() {
    this.SetHeight()
  },
  watch: {
    height() {
      this.SetHeight();
    }
  },
  methods: {
    SetHeight() {
      if (this.height)
        this.vHeight = this.height + 'px'
    },
    GoUrl() {
      if (this.titleUrl)
        window.open(this.titleUrl, '_blank');
    }
  },
}
</script>

<style scoped>
.card-container {
  background: #fff;
  padding: 10px 20px 20px 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: solid 1px #ededed;
  box-shadow: 2px 4px 7px #c6c6c645;
  overflow-y: auto;
}

.no-padding-bottom {
  padding-bottom: 0px;
}

.card-title,
.card-title h2 {
  font-weight: 600;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  color: var(--text-color-black);
}

.sub-title {
  color: var(--info-gray);
  font-size: 14px;
  position: relative;
  display: block;
}

.slot-actions {
  float: right;
  position: absolute;
  right: 30px;
  top: 27px;
}

.div-title {
  display: block;
}

.is-link {
  cursor: pointer;
  color: #409EFF !important;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  border-radius: 53px;
}

::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 8px;
}
</style>