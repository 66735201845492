<template>
  <div>
    <div class="percent-bar-container" v-if="proxy" v-bind:class="{'blocked' : proxy.Blocked}">
      <el-tooltip :content="PercentualSucesso + '%'" placement="top">
        <div class="percent-bar success" :style="{ width: PercentualSucesso + '%' }">
          <span v-show="PercentualSucesso >= 20">{{ PercentualSucesso }}%</span>
        </div>
      </el-tooltip>
      <el-tooltip :content="PercentualErro + '%'" placement="top">
        <div class="percent-bar error" :style="{ width: PercentualErro + '%' }">
          <span v-show="PercentualErro >= 20">{{ PercentualErro }}%</span>
        </div>
      </el-tooltip>
    </div>
    <div v-else>
      <p class="info-no-data">Sem dados</p>
    </div>
  </div>
</template>


<script>

export default {
  name: "GraficoAcerto",
  props: ['idCia', 'proxy', 'erroMax'],
  data() {
    return {};
  },
  created() {
  },
  mounted() {
  },
  components: {},
  watch: {},
  methods: {},
  computed: {
    Taxa() {
      let retorno = null;
      if (this.proxy)
        retorno = this.proxy.ProxyTaxas.find(x => x.IdCia === this.idCia)
      return retorno;
    },
    PercentualSucesso() {
      let retorno = 0;
      if (this.Taxa)
        retorno = parseFloat(this.Taxa.TaxaSucesso.toFixed(1));
      return retorno;
    },
    PercentualErro() {
      let retorno = 0;
      if(this.Taxa) {
        let txErro = 100 - this.Taxa.TaxaSucesso;
        retorno = parseFloat(txErro.toFixed(1));
      }
      return retorno;
    }
  }
}
</script>

<style scoped>
.percent-bar-container {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #f0f0f0;
}

.percent-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
}

.success {
  background-color: #28a745;
}

.blocked .success {
  background-color: rgba(40, 167, 69, 0.15);
}

.error {
  background-color: #dc3545;
}

.blocked .error {
  background-color: rgba(220, 53, 69, 0.15);
}

.info-no-data {
  font-size: 10px;
  top: 10px;
  position: relative;
  color: #999;
}
</style>
