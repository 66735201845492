<template>
  <el-drawer
      title="Editar Proxy"
      :visible.sync="drawer"
      size="40%"
      :before-close="OnClose">
    <el-form class="form-proxy">
      <el-row :gutter="20" v-if="proxy">
        <el-col>
          <el-form-item label="Name">
            <el-input v-model="proxy.Name" @change="build"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="Subnet">
            <el-switch
                v-model="proxy.IsSubnet"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="build"
            />
          </el-form-item>
        </el-col>
        <el-col v-if="!proxy.IsSubnet">
          <el-form-item label="Proxy">
            <el-input v-model="proxy.Config" @change="build"></el-input>
          </el-form-item>
        </el-col>
        <div v-if="!proxy.IsSubnet">
          <el-col :span="12">
            <el-form-item label="Usuário">
              <el-input v-model="form.User" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Senha">
              <el-input v-model="form.Pass" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Host">
              <el-input v-model="form.Host" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Porta">
              <el-input v-model="form.Port" readonly />
            </el-form-item>
          </el-col>
        </div>
        <el-col v-else>
          <subnet :proxy="proxy" />
        </el-col>
      </el-row>
    </el-form>
  </el-drawer>
</template>


<script>
import ProxyManagerService from "@/services/ProxyManagerService";
import Subnet from "@/components/Proxy/Subnet.vue";

export default {
  name: "EditProxy",
  components: {Subnet},
  props: ['proxy'],
  data() {
    return {
      Proxies: [],
      drawer: false,
      form: {
        User: '',
        Pass: '',
        Host: '',
        Port: '',
      },
      activeCias: [],
      builded: false
    };
  },
  created() {
  },
  mounted() {
  },
  watch: {
    drawer(val) {
      if (val)
        this.build();
      else {
        this.proxy.Edited = true;
        this.proxy.ActiveInIdCias = this.activeCias
      }
    }
  },
  methods: {
    Gravar() {
      if (!this.proxyManagerService)
        this.proxyManagerService = new ProxyManagerService(this)
    },
    OnClose() {
      this.drawer = false;
      this.$emit('close');
    },
    clearConfig() {
      this.form.User = '';
      this.form.Pass = '';
      this.form.Host = '';
      this.form.Port = '';
    },
    build() {
      this.clearConfig();
      if (!this.proxy.IsSubnet) {
        try {
          let config = this.proxy.Config.split(':');
          this.form.User = config[0];
          this.form.Pass = config[1].split('@')[0];
          this.form.Host = config[1].split('@')[1];
          this.form.Port = config[2];
        } catch {
          console.log('Erro ao montar configuração do proxy')
        }
      }

      this.buildCiasAtivas()
    },
    buildCiasAtivas() {
      this.activeCias = this.proxy.ProxyCia.filter(x => x.IdCia).map(x => x.IdCia)
    }
  },
  computed: {
    Cias() {
      let parent = this.$UtilMbx.GetParent(this, 'ProxyManager')
      return parent.ListaCompanhias
    }
  }
};
</script>

<style scoped>
.form-proxy {
  width: 100%;
  padding: 20px;
}
</style>
