<template>
	<section class="container-modal">
		<el-dialog :visible.sync="visibleUpdate" :width="util.IsCompact(768) ? '90%' : '50%'">
			<template slot="title">
				<h5>Adicionar {{ title }}</h5>
			</template>
			<el-row class="card-container" v-for="(modal, index) in gridData" :key="index" :gutter="30">
				<div v-if="!modal.hide">
					<el-col class="card-content" :span="21" :xs="18" @click="closeModal(modal)">
						<el-col class="image-content" :span="6">
							<img :src="modal.image" :alt='"Ícone" + modal.title' width="120">
						</el-col>
						<el-col class="card-title">
							<h6>{{ modal.title }}</h6>
							<span description>{{ modal.subtitle }}</span>
						</el-col>
					</el-col>
					<el-col class="card-button" :span="3" :xs="6">
						<el-button @click="closeModal(modal)" type="info" circle icon="el-icon-check"></el-button>
					</el-col>
				</div>
			</el-row>
		</el-dialog>
	</section>
</template>

<script>
import UtilService from "@/services/UtilService";

export default {
	name: 'ModalSubmenu',
	props: ['modalVisible', 'gridData', 'title'],
	data() {
		return {
			visibleUpdate: false,
			widthScreen: '',
		}
	},
	created() {
		this.util = new UtilService(this)
	},
	updated() {
		this.$emit('updateModalVisible', this.visibleUpdate)
		if (this.widthScreen !== window.screen.availWidth) {
			this.screenWidth()
		}
	},
	methods: {
		screenWidth() {
			if (this.widthScreen) {
				this.widthScreen = ''
				console.log(this.widthScreen)
			}
			console.log(this.widthScreen)
			return this.widthScreen = window.screen.availWidth
		},
		closeModal(obj) {

			if (obj && obj.urlRedirect) {
				this.visibleUpdate = false;
				return this.$router.push(obj.urlRedirect);
			}

			const tag = this.$createElement
			this.$message({
				dangerouslyUseHTMLString: true,
				center: true,
				duration: 3000,
				type: 'info',
				message:
					tag('h5', null,
						[
							tag('span', null, 'Funcionalidade disponível '),
							tag('i', { style: { color: 'blue', fontWeight: 'bold' } }, 'EM BREVE!'),
						]),
			})
			this.visibleUpdate = false
		},
	},
	watch: {
		modalVisible() {
			return this.visibleUpdate = this.modalVisible
		},
	},
}
</script>

<style scoped>
.el-divider--horizontal {
	margin: 0 0 24px 0;
}

.card-content {
	display: flex;
	min-height: 80px;
}

.image-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-title>h6 {
	color: #409EFF;
	font-size: 1.15rem;
	font-weight: bold;
	margin-top: 12px;
}

.card-button {
	min-height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 900px) {

	.image-content,
	.card-button {
		padding-top: 12px;
		align-items: flex-start;
	}
}
</style>