export default class ScreenResizeService {
    constructor(source) {
        this._source = source;
    }

    Start() {
        this._SetValues(this._source);
        let ref = this;
        window.addEventListener('resize', () => {
            ref._SetValues(ref._source);
        })
    }

    _SetValues(source) {
        let size = ''
        source.$screen.xl = false
        source.$screen.lg = false
        source.$screen.md = false
        source.$screen.sm = false
        source.$screen.xs = false

        if(window.innerWidth >= 1920) {
            source.$screen.xl = true;
            size = 'xl';
        } else if(window.innerWidth >= 1200) {
            source.$screen.lg = true
            size = 'lg';
        } else if(window.innerWidth >= 992) {
            source.$screen.md = true
            size = 'md';
        } else if(window.innerWidth >= 768) {
            source.$screen.sm = true
            size = 'sm';
        } else if(window.innerWidth < 768) {
            source.$screen.xs = true
            size = 'xs';
        }

        if(source.$screen.size !== size)
            source.$screen.size = size;
    }
}