<template>
  <div id="app">
    <div id="wrapper" v-bind:class="{ 'full-width-content': forceSubscription }">
      <drag-menu-mobile ref="sideMenu" v-click-outside="clickOutMenu" v-if="ShowMenuEsquerdo"
        :pessoa-logada="PessoaLogada" @change="menuLateralAberto = $event">
      </drag-menu-mobile>
      <cabecalho id="div-cabecalho" ref="divCabecalho" :show="!IsExternalPage()" @done="showContent = true"
        :usuario-logado="PessoaLogada" :plano-atual="PlanoAtual"></cabecalho>
      <div
        :class="{ 'content-page': menuLateralAberto || util.IsCompact(), 'content-no-menu': !menuLateralAberto && !util.IsCompact(), }"
        class="content-pagebackground-container" v-if="!IsExternalPage() && usuarioAutenticado && logadoComoVerificado">
        <!-- Start content -->
        <transition enter-active-class="animated fadeIn">
          <div v-show="showContent" id="div-content" class="content" @scroll="$refs.divCabecalho.handleScroll">
            <!-- Page-Title -->
            <div class="margin-top-10" v-bind:class="{ 'container': setContainer }">
              <header-page ref="headerPage" />
              <router-view @upgradePlano="upgradePlano($event)" @changeMarketplace="changeMarketplace($event)"
                @changeTrivelo="changeTrivelo($event)" />
            </div>
            <!-- end container -->
            <rodape id="div-rodape"></rodape>
          </div>
        </transition>
        <!-- end content -->
      </div>
      <!-- LOGIN PAGE -->
      <router-view v-if="IsExternalPage()" @LoginSuccess="handleLoginSuccess" @SetupConcluido="AbrirComponentes" />
      <div class="div-background-loading" v-show="loadingMainPage">
        <loading-page></loading-page>
      </div>
      <loading-token v-show="!usuarioAutenticado && !IsExternalPage()"></loading-token>
      <modal-submenu title="canais de venda" :modalVisible="showModalCanais" :gridData="dataModalCanais"
        @updateModalVisible="showModalCanais = $event" />
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ParceiroService from "@/services/ParceiroService";
import UtilService from "@/services/UtilService";
import Cabecalho from "./components/layout/CabecalhoLayout/cabecalho";
import LoadingPage from "./components/layout/LoadingPage";
import Rodape from "./components/layout/Rodape";
import LoadingToken from "./components/Shared/LoadingToken";
import DragMenuMobile from "./components/Shared/DragMenuLateral";
import ClienteService from "./services/ClienteService";
import LoginService from "./services/LoginService";
import ModalSubmenu from "@/components/layout/componentsMenuLateral/ModalSubmenu";
import ScreenResizeService from "@/services/ScreenResizeService";
import HeaderPage from "@/components/layout/HeaderPage.vue";
import { debounce } from 'lodash';


export default {
  name: "App",
  directives: {
    ClickOutside,
  },
  components: {
    HeaderPage,
    ModalSubmenu,
    cabecalho: Cabecalho,
    "loading-page": LoadingPage,
    "loading-token": LoadingToken,
    DragMenuMobile,
    Rodape,
  },
  data() {
    return {
      rotasSemAutenticacao: ["Cadastro", "LoginHash", "Login", "Setup", "CadastroIframe", "CadastroCompleto", "CadastroInicial"],
      showContent: false,
      isNavigating: false,
      name: 'App',
      HideHeader: false,
      HideFooter: false,
      HideMenu: false,
      loadingMainPage: false,
      usuarioAutenticado: false,
      showComingSoon: false,
      chatOn: false,
      buildMenuEsquerdo: false,
      ParceiroAtual: null,
      PessoaLogada: null,
      clickedShowMenu: false,
      LogadoComo: null,
      lanStarted: false,
      menuLateralAberto: false,
      logadoComoVerificado: false,
      showModalCanais: false,
      dataModalCanais: null,
      forceSubscription: false,
      isAdmin: false,
      ignoreTransitionContent: false
    };
  },
  created() {
    this.loginService = new LoginService(this);
    this.screenResizeService = new ScreenResizeService(this);
    this.screenResizeService.Start();
    this.util = new UtilService(this);
    this.$moment.locale('pt-br');
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.verificarAutenticacao().then((autenticado) => {
        console.log("Autenticação verificada:", autenticado);
        if (!autenticado && !vm.IsExternalPage() && to.name !== 'Login') {
          vm.RedirectLogin();
        }
      });
    });
  },
  mounted() {
    this.verificarAutenticacao().then((autenticado) => {
      if (!autenticado && !this.IsExternalPage() && this.$route.name !== 'Login') {
        this.RedirectLogin();
      }
    });
    setInterval(() => {
      this.AjustarAlturaConteudo();
    }, 1500);
    console.log("Moblix Painel - versão: " + process.env.VUE_APP_BUILD_INFO);
    console.log("Ambiente: " + process.env.NODE_ENV);
  },
  methods: {
    handleLoginSuccess() {
      this.verificarAutenticacao().then((autenticado) => {
        if (autenticado) {
          this.RedirecionarPaginaInicial();
        }
      });
    },
    verificarAutenticacao() {
      return new Promise((resolve) => {
        if (this.loginService && this.loginService.PegarToken()) {
          this.usuarioAutenticado = true;
          this.PegarUsuario(() => {
            resolve(true);
          });
        } else {
          this.usuarioAutenticado = false;
          resolve(false);
        }
      });
    },
    ShowModalMenuCanais(data) {
      this.dataModalCanais = data;
      this.showModalCanais = true;
    },
    clickOutMenu() {
      if (this.MenuLateralAberto && this.util.IsCompact() && this.$refs.sideMenu)
        this.$refs.sideMenu.FecharMenu();
    },
    IniciaLinguagem(res, callback) {
      this.$StartLanguage(callback, res);
    },
    Start() {
      this.parceiroService = new ParceiroService(this);

      setTimeout(() => {
        if (this.loginService && this.loginService.PegarToken()) {
          this.PegarUsuario();
        } else if (!this.$route.params.Hash && !this.IsExternalPage()) {
          this.RedirectLogin();
        }
      }, 100);
    },
    // Método responsável por calcular a altura da div de conteúdo principal
    // Isso permite criar uma barra de rolagem (scrollbar, overflow-y) exata
    // Fazer isso permite que não haja quebra de layout nas animações de modais do bootstrap e element ui além da modal full-screen
    AjustarAlturaConteudo() {
      if (this.$route.name && this.$route.name.toLowerCase() !== "setup") {
        document.getElementsByTagName("html")[0].style.overflow = "hidden";
        let querySelector = document.querySelectorAll("#div-cabecalho");
        let divContent = document.getElementById("div-content");
        if (querySelector.length && divContent) {
          let alturaCabecalho = querySelector[0].offsetHeight;
          let alturaJanela =
            document.documentElement.clientHeight - alturaCabecalho;
          divContent.style.height = alturaJanela + "px";
        }
      } else {
        document.getElementsByTagName("html")[0].style.overflow = "auto";
      }
    },
    /** @return {boolean} */
    IsExternalPage() {
      if (!this.$route.name) {
        return false;
      }

      const externalPaths = ['/cadastro-inicial', '/monte-sua-loja'];
      return this.rotasSemAutenticacao.includes(this.$route.name) ||
        externalPaths.includes(this.$route.path) ||
        this.$route.path.startsWith('/Login');
    },
    PegarUsuario(callback) {
      this.PessoaLogada = null;
      this.ParceiroAtual = null;
      this.clienteService = new ClienteService(this);
      this.clienteService.PegarUsuarioAtual(
        (res) => {
          this.callbackPegarUsuario(res);
          this.usuarioAutenticado = true;
          if (callback) callback();
        },
        (erro) => {
          this.RedirectErro(erro);
          this.usuarioAutenticado = false;
          if (callback) callback();
        }
      );
    },
    callbackPegarUsuario(res) {
      if (res.HasResult) {
        this.PessoaLogada = res.Data[0];
        this.ParceiroAtual = this.PessoaLogada.Parceiro;
        this.IniciaLinguagem(res.Data[0].Parceiro.Configuracao.IdiomaPainel, this.AbrirComponentes)
        this.isAdmin = res.Data[0].IdPermissao === 1
      } else {
        this.RedirectLogin();
      }
    },
    ocultarSuporte() {
      var tag = document.getElementsByClassName('woot--bubble-holder')
      if (tag && tag.length) {
        tag[0].remove();
      } else {
        setTimeout(() => {
          this.ocultarSuporte()
        }, 50)
      }
    },
    VerificarPermissaoAgencia() {
      if (this.PessoaLogada) {
        if (this.PessoaLogada.IdPermissao !== 1 && this.PlanoAtual && !this.AgenciaAlvo.PeriodoAvaliacao && (this.AgenciaAlvo.Inadimplante || !this.AgenciaAlvo.PossuiPlanoAtivo)) {
          let rotasPermitidas = ["login", "migrando plano", "meu plano", "faturas parceiro"];
          if (!rotasPermitidas.find((x) => x === this.$route.name.toLowerCase())) {
            this.$router.push("/MeuPlano");
            this.buildMenuEsquerdo = !this.AgenciaAlvo.Inadimplante;
            this.forceSubscription = this.AgenciaAlvo.Inadimplante;
            this.$forceUpdate()
          }
        } else {
          this.forceSubscription = false;
        }
      } else {
        //console.log("PessoaLogada não definido! ")
      }
    },
    AbrirComponentes() {
      this.buildMenuEsquerdo = true;
      this.usuarioAutenticado = true;

      let newAgencyBySession = sessionStorage.getItem('newAgency')
      sessionStorage.removeItem('newAgency')

      if (this.AgenciaAlvo.Configuracao.NovaAgencia || newAgencyBySession) {
        this.$router.push("/setup");
      } else if (this.IsExternalPage() || (this.$route.path.toLowerCase() === '/agencia/admin' && this.PessoaLogada.IdPermissao !== 1))
        this.RedirecionarPaginaInicial();

      // Se Plano Grátis
      if (this.AgenciaAlvo.IdPlano === 3)
        this.ocultarSuporte();
    },
    RedirecionarPaginaInicial() {
      let path = null;
      if (this.PessoaLogada) {
        switch (this.PessoaLogada.IdPermissao) {
          case 6: // Marketing
            path = "/";
            break;
          case 7: // Financeiro
            path = "/ListaFornecedor";
            break;
          default:
            path = "/";
            break;
        }

        if (this.$route.path !== path && !this.isNavigating) {
          this.isNavigating = true;
          window.scrollTo(0, 0);
          this.$router.push(path).finally(() => {
            this.isNavigating = false;
          });
        }
      }
    },
    RedirectLogin() {
      if (!this.$route || !this.$route.name) {
        return;
      }
      if (!this.IsExternalPage() && this.$route.name !== 'Login' && this.$router && !this.usuarioAutenticado && !this.isNavigating) {
        this.isNavigating = true;
        this.$router.push("/login").finally(() => {
          this.isNavigating = false;
        });
      } else {
        console.log("Página externa, não redirecionar para login.");
      }
    },
    RedirectErro(erro) {
      console.error(erro);
      this.RedirectLogin();
    },
    changeMarketplace(possuiMarketplace) {
      this.$refs.menuLateral.possuiMarketPlaceHabilitado = possuiMarketplace
    },
    changeTrivelo(possuiTrivelo) {
      this.$refs.menuLateral.possuiTriveloHabilitado = possuiTrivelo
    },
    upgradePlano() {
      this.$refs.sideMenu.$refs.menuLateral.$refs.helpBox.MigrouPlano();
    },
    // Utilizado por componentes filhos
    AtualizarParceiroAtual() {
      this.parceiroService.Pegar((res) => {
        this.ParceiroAtual = res.Data[0];
      }, null, true);
    },
    // Utilizado por componentes filhos
    ShowLateralMenu() {
      if (this.util.IsCompact()) {
        if (!this.$refs.sideMenu.isOpen)
          this.$refs.sideMenu.AbrirMenu();
        else
          this.$refs.sideMenu.FecharMenu();
      } else {
        this.$refs.sideMenu.Recolher()
      }
    }
  },
  computed: {
    setContainer() {
      if (this.$route.path.includes('Lista') || this.$route.path.includes('Produtos')) {
        return false;
      }
      let noContainer = ['default', 'editar código'];
      const routeName = this.$route.name ? this.$route.name.toLowerCase() : '';
      return !noContainer.includes(routeName) && !this.$screen.sm && !this.$screen.xs;
    },
    MenuLateralAberto() {
      let retorno = false;
      if (this.$refs.sideMenu) {
        retorno = this.$refs.sideMenu.isOpen
      }
      return retorno;
    },

    ShowMenuEsquerdo() {
      return this.PessoaLogada &&
        !this.IsExternalPage() &&
        this.buildMenuEsquerdo &&
        this.logadoComoVerificado
    },
    PlanoAtual() {
      if (this.AgenciaAlvo && this.AgenciaAlvo.ParceiroPlano)
        return this.AgenciaAlvo.ParceiroPlano
      else
        return null
    },
    AgenciaAlvo() {
      return this.LogadoComo ?? this.ParceiroAtual
    }
  },
  watch: {
    usuarioAutenticado(novoValor) {
      if (novoValor && this.$route.name === 'Login') {
        this.RedirecionarPaginaInicial();
      }
    },

    $route: {
      immediate: true,
      handler: debounce(function (to) {
        if (!this.usuarioAutenticado && !this.IsExternalPage() && to.name !== 'Login' && !this.isNavigating) {
          this.verificarAutenticacao().then((autenticado) => {
            if (!autenticado) {
              this.RedirectLogin();
            }
          });
        }
        if (to.name === 'Login' && !to.params.Hash) {
          this.VerificarPermissaoAgencia();
        }


        setTimeout(() => {
          if (!this.ignoreTransitionContent) {
            this.showContent = false;
            setTimeout(() => {
              this.showContent = true;
            })
          }
        })

      }, 300)

    }
  }
};
</script>
<style>
#app {
  height: 100%;
}

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';

@import "./assets/template/css/style.css";
@import "./assets/template/css/custom.css";
@import "./assets/menu-esquerdo.css";
@import "./assets/ScrollbarStyle.css";
@import "./assets/geral.css";
@import "./assets/theme-override-template.css";
@import "./assets/externo/AnimateCSS/animate.css";
@import "./assets/externo/placeholder-loading/placeholder-loading.min.css";

#div-content {
  overflow-y: auto;
}

@media screen and (min-width: 992px) {
  .content-page {
    margin-left: 250px !important;
    transition: all 0.3s linear;
  }
}

@media screen and (max-width: 991px) {
  .content-page {
    margin-left: 0px !important;
  }
}

.content-pagebackground-container {
  margin-left: 60px;
  transition: all 0.3s linear;
}

.full-width-content .container-header,
.full-width-content .content-page {
  margin-left: 0px !important;
}
</style>
