import HttpService from "./HttpService";

export default class MarketPlaceService {
  constructor(source) {
    this.source = source;
    this.httpService = new HttpService(
      this.source,
      process.env.VUE_APP_URL_MARKETPLACE,
      true
    );
  }

  Get(filtro, callback, callbackErro) {
    let query = filtro ? this.httpService.JsonToQueryString(filtro) : "";
    return this.httpService.Get("marketplace" + query, callback, callbackErro);
  }

  Post(obj, callback, callbackErro) {
    return this.httpService.Post(`marketplace`, obj, callback, callbackErro);
  }

  Put(obj, callback, callbackErro) {
    return this.httpService.Put(`marketplace`, obj, callback, callbackErro);
  }

  PostGoogleTTD(obj, callback, callbackErro) {
    return this.httpService.Post(`GoogleTtd`, obj, callback, callbackErro);
  }
}
