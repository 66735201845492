<template>
  <footer v-if="!isHomeRoute" ref="footer" :class="{'footer' : !setFooterAbsolute, 'footer-absolute': setFooterAbsolute}">
    <el-row>
      <el-col>
        <div class="container-span">
          <span class="span-left"><el-link href="https://atendimento.moblix.com.br" type="primary" target="_blank">
            <i class="el-icon-question"></i></el-link> Dúvidas?</span>
          <span class="span-right">
          <el-link class="link-help" :href="Help.url" type="primary" target="_blank">{{ Help.label }}
            <v-icon name="external-link-alt"/>
          </el-link>
          </span>
        </div>
      </el-col>
    </el-row>
  </footer>
</template>

<script>
import Icon from 'vue-awesome/components/Icon'

export default {
  components: {
    'v-icon': Icon
  },
  data() {
    return {
      setFooterAbsolute: true
    }
  },
  created() {
  },
  mounted() {
    setInterval(() => {
      this.Ajustar()
    }, 1000)
  },
  watch: {
    $route() {
      this.Ajustar()
    }
  },
  methods: {
    Ajustar() {
      let divContent = document.querySelector('#div-content div');
      if (divContent) {
        let alturaContent = divContent.offsetHeight,
            posicaoBottomContent = divContent.offsetTop + alturaContent,
            posicaoFooter = window.innerHeight - 80

        if (posicaoBottomContent > posicaoFooter) {
          this.setFooterAbsolute = false;
        } else {
          this.setFooterAbsolute = true;
        }
      }
    }
  },
  computed: {
    isHomeRoute() {
      return this.$route.path === '/';
    },
    Help() {
      switch (this.$route.path.toLowerCase()) {
        case '/pessoa':
          return {
            url: 'https://atendimento.moblix.com.br/docs/conheca-a-moblix/minha-conta/como-cadastrar-novos-usuarios-no-painel-da-moblix/',
            label: 'Como cadastrar novos usuários'
          }
        case '/meuplano':
          return {
            url: 'https://atendimento.moblix.com.br/docs/conheca-a-moblix/sobre-a-moblix/qual-e-o-custo-da-moblix/',
            label: 'Quais as formas de pagar a mensalidade da MOBLIX?'
          }
        case '/listapedidos':
          return {
            url: 'https://atendimento.moblix.com.br/docs/integracoes/passagens-aereas/como-emitir-um-bilhete-aereo-na-plataforma-moblix/',
            label: 'Como fazer a emissão de uma passagem aérea?'
          }
        case '/listacupons':
          return {
            url: 'https://atendimento.moblix.com.br/docs/impulsione-seu-negocio/how-to-faqs/cupons-e-promocoes/como-criar-cupons-de-desconto/',
            label: 'Como criar cupons de desconto?'
          }
        case '/produtos':
          return {
            url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/produtos/como-cadastrar-um-produto-na-moblix/',
            label: 'Como cadastrar um produto?'
          }
        case '/valorescias':
          return {
            url: 'https://atendimento.moblix.com.br/base-de-conhecimento/como-configurar-o-buscador-de-passagens-aereas-da-plataforma-moblix/',
            label: 'Como configurar o buscador de passagens aéreas da plataforma MOBLIX?'
          }
        case '/listapontos':
          return {
            url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/primeiros-passos/como-configurar-o-buscador-de-passagens-aereas-da-plataforma-moblix/',
            label: 'Como configurar o buscador de passagens aéreas da plataforma MOBLIX?'
          }
        case '/agencia/pagamentos':
          return {
            url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/meios-de-pagamento/como-configurar-o-pagseguro-no-meu-site/',
            label: 'Como configurar o PagSeguro?'
          }
        case '/integracoes':
          return {url: 'https://atendimento.moblix.com.br/docs/integracoes/', label: 'Como configurar as integrações?'}
        case '/Gerador':
          return {
            url: 'https://atendimento.moblix.com.br/docs/impulsione-seu-negocio/how-to-faqs/como-criar-campanhas-e-identificar-a-origem-do-trafego/',
            label: 'Como criar campanhas e identificar a origem do tráfego?'
          }
        case '/rascunhos':
          return {
            url: 'https://atendimento.moblix.com.br/base-de-conhecimento/como-gerar-links-de-pagamento/',
            label: 'Como gerar links de pagamento?'
          }
        case '/site':
          return {
            url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/layout/como-configurar-o-layout-do-seu-site-moblix/',
            label: 'Como configurar o layout do meu site?'
          }
        case '/push':
          return {
            url: 'https://atendimento.moblix.com.br/docs/impulsione-seu-negocio/how-to-faqs/como-configurar-a-sua-plataforma-push/',
            label: 'Como configurar a plataforma push?'
          }
        case '/cardPesquisa':
          return {
            url: 'https://atendimento.moblix.com.br/docs/impulsione-seu-negocio/aplicativos/como-configurar-um-widget-buscador-de-voos-da-moblix-em-outro-site/',
            label: 'Como configurar um wideget buscador de voos da Moblix em outro site'
          }
        case '/conteudo':
          return {
            url: 'https://atendimento.moblix.com.br/base-de-conhecimento/como-criar-um-artigo-no-blog-do-meu-site/',
            label: 'Como criar um artigo no blog do meu site?'
          }
        case '/depoimento':
          return {
            url: 'https://atendimento.moblix.com.br/docs/conheca-a-moblix/minha-conta/como-cadastrar-depoimentos-no-meu-site/',
            label: 'Cadastre os depoimentos dos seus clientes'
          }
        case '/aplicativos':
          return {
            url: 'https://atendimento.moblix.com.br/docs/impulsione-seu-negocio/aplicativos/como-funciona-a-loja-de-apps/',
            label: 'Como funciona a loja de aplicativos da Moblix?'
          }
        case '/email':
          return {
            url: 'https://atendimento.moblix.com.br/docs/impulsione-seu-negocio/how-to-faqs/e-mail-marketing/como-enviar-um-e-mail-marketing-usando-o-painel-da-moblix/',
            label: 'Como enviar um email marketing'
          }
        case '/agencia':
          return {url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/', label: 'Configure sua agência'}
        case '/agencia/preferences':
          return {
            url: 'https://atendimento.moblix.com.br/docs/impulsione-seu-negocio/how-to-faqs/redes-sociais/passos-para-oferecer-o-cadastro-e-login-com-o-facebook-em-seu-site-moblix/',
            label: 'Fazer o login utilizando o Google e Facebook'
          }
        case '/agencia/dominios':
          return {
            url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/dominios/',
            label: 'Como configurar o meu domínio próprio'
          }
        case '/agencia/usuarios':
          return {
            url: 'https://atendimento.moblix.com.br/docs/conheca-a-moblix/minha-conta/como-cadastrar-novos-usuarios-no-painel-da-moblix/',
            label: 'Como cadastrar novos usuários no painel da Moblix?'
          }
        case '/agencia/legal':
          return {
            url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/primeiros-passos/como-alterar-os-termos-de-uso-e-a-pagina-sobre-do-site/',
            label: 'Como alterar os termos de uso e a página sobre do site'
          }
        case '/configuracaopesquisa':
          return {
            url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/primeiros-passos/como-configurar-o-buscador-de-passagens-aereas-da-plataforma-moblix/',
            label: 'Como configurar o buscador de voos da Moblix'
          }
        case '/marketplace':
          return {
            url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/produtos/como-criar-um-marketplace-e-selecionar-fornecedores/',
            label: 'Como vender produtos no Marketplace Moblix'
          }
        case '/agencia/notification':
          return {
            url: 'https://atendimento.moblix.com.br/docs/impulsione-seu-negocio/vendas/como-personalizar-o-conteudo-dos-e-mails-automaticos-pre-configurados/',
            label: 'Como personalizar os e-mails enviados pela plataforma'
          }
        case '/experienciamarketplace':
          return {
            url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/produtos/como-criar-um-marketplace-e-selecionar-fornecedores/',
            label: 'Como criar um marketplace e selecionar fornecedores'
          }
        case '/agencia/idiomas-moedas':
          return {
            url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/layout/como-definir-o-idioma-e-a-moeda-do-meu-site-na-moblix/',
            label: 'Como definir o idioma e a moeda do meu site na Moblix?'
          }
        case '/headless':
          return {
            url: 'https://atendimento.moblix.com.br/docs/configure-sua-loja/layout/como-editar-o-codigo-do-layout-do-meu-site/',
            label: 'Como editar o código do layout do seu site'
          }
        case '/hotelconfig':
          return {
            url: 'https://atendimento.moblix.com.br/docs/integracoes/hoteis/como-funciona-a-integracao-da-bestbuy-hoteis-com-a-moblix',
            label: 'Como funciona a integração com a BestBuy Hotéis?'
          }
        default:
          return {url: 'https://atendimento.moblix.com.br/', label: 'Acesse a Central de Atendimento'};
      }
    }
  },
}
</script>

<style scoped>
.container-span {
  border: solid 1px #d8d8d8;
  width: fit-content;
  padding: 15px 20px;
  border-radius: 4px;
}

.span-left {
  padding: 0px 10px 5px 0px;
  border-right: solid 1px #d8d8d8;
}

.span-left i {
  position: relative;
  top: -2px;
}

.span-right {
  margin-left: 8px;
}

.span-right .fa-icon {
  position: relative;
  left: 3px;
  bottom: 1px;
}

.span-right a {
  position: relative;
  bottom: 2px;
}

.footer {
  bottom: 0;
  color: #58666e;
  text-align: center;
  right: 0;
  left: 240px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.footer-absolute {
  position: absolute;
  bottom: 20px !important;
  left: 270px;
}
</style>
