import Vue from "vue";
import App from "./App";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale";
import lang from "element-ui/lib/locale/lang/pt-br";
import VueGtm from "@gtm-support/vue2-gtm";
import BootstrapVue from "bootstrap-vue";
import money from "v-money";
import Icon from "vue-awesome/components/Icon";
import JsonExcel from "vue-json-excel";
import VueTheMask from "vue-the-mask";
import UUID from "vue-uuid";
import * as VueGoogleMaps from "vue2-google-maps";
import router from "./router";
import GlobalUtil from "./Plugins/GlobalUtil";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { SchedulePlugin } from "@syncfusion/ej2-vue-schedule";
import { registerLicense } from "@syncfusion/ej2-base";
import VueMoment from "vue-moment";
import SchedulerWords from "@/assets/externo/Syncfusion/SchedulerLicense.json";
import VueTypedJs from "vue-typed-js";
import OneSignalVue from "onesignal-vue";
import VueMeta from "vue-meta";
import FlagIcon from "vue-flag-icon";

/*************************************** IMPORT CSS FILES ****************************************************/
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "element-ui/lib/theme-chalk/fonts/element-icons.ttf";
import "element-ui/lib/theme-chalk/fonts/element-icons.woff";
import "element-ui/lib/theme-chalk/index.css";
import "vue-awesome/icons";
import "vue-awesome/icons/flag";

import moment from "moment";
import UtilService from "@/services/UtilService";

Vue.component("icon", Icon);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueTypedJs);
Vue.use(BootstrapVue);
Vue.use(ElementUI, { lang });
Vue.use(UUID);
Vue.use(VueTheMask);
Vue.use(VueMeta);
Vue.use(GlobalUtil);
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  suffix: "",
  precision: 2,
  masked: false,
});
Vue.use(FlagIcon);
Vue.use(VueMoment, {
  moment,
});

Vue.prototype.$UtilMbx = new UtilService(this);
Vue.prototype.$screen = Vue.observable({
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  size: "",
});

Vue.use(SchedulePlugin);
registerLicense(SchedulerWords.license);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY_API,
    libraries: "places, visualization",
  },
  installComponents: true,
});

Vue.use(OneSignalVue);

Vue.mixin({
  data() {
    return {};
  },
  methods: {
    getIsAfiliadoTrivelo() {
      var host = window.location.host;
      var urlQuery = new URLSearchParams(window.location.search);
      return urlQuery.get("afiliado") != null || host.indexOf("afiliado") > -1;
    },
    getIsAfiliadoMoblix() {
      var host = window.location.host;
      var urlQuery = new URLSearchParams(window.location.search);
      return urlQuery.get("partners") != null || host.indexOf("partners") > -1;
    },
    isBase64(str) {
      try {
        return btoa(atob(str)) === str;
      } catch (err) {
        return false;
      }
    }
  },
});

Vue.use(VueGtm, {
  id: process.env.NODE_ENV === "development" ? "GTM-5PZ8MGKM" : "GTM-WNJJF4Q",
  debug: process.env.NODE_ENV === "development",
  loadScript: true,
  vueRouter: router,
});

Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");

locale.use(lang);
Vue.config.productionTip = false;
