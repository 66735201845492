<template>
  <div ref="DragContainerMenu"
       class="menu-container"
       v-bind:class="{'animated-menu-item' : animated }">
    <menu-lateral
        ref="menuLateral"
        :pessoa-logada="pessoaLogada"/>
  </div>
</template>

<script>
import UtilService from "@/services/UtilService";
import MenuLateral from "@/components/layout/MenuLateral";

export default {
  name: 'DraggableDiv',
  components: {MenuLateral},
  props: ['pessoaLogada'],
  data: function () {
    return {
      animated: false,
      active: false,
      currentX: 0,
      initialX: 0,
      xOffset: 0,
      direcao: null,
      timeStart: null,
      dragTime: null,
      realStartX: 0,
      realEndX: 0,
      widthSubcontainer: '100%',
      isOpen: false,
      ignoreClickout: false
    }
  },
  created() {
    this.util = new UtilService(this)
  },
  mounted() {
    if (this.util.IsCompact()) {
      this.FecharMenu();
    } else {
      this.AbrirMenu();
    }
    this.animated = true;
    // Desabilitando menu interativo com toque
    // this.StartDragble();
    window.addEventListener('resize', this.Resize);
    this.Resize();
  },
  destroyed() {
    window.removeEventListener('resize', this.Resize);
  },
  methods: {
    StartDragble() {
      let ref = this
      setTimeout(() => {
        ref.container.addEventListener("touchstart", ref.dragStart, false);
        ref.container.addEventListener("touchend", ref.dragEnd, false);
        ref.container.addEventListener("touchmove", ref.drag, false);

        ref.container.addEventListener("mousedown", ref.dragStart, false);
        ref.container.addEventListener("mouseup", ref.dragEnd, false);
        ref.container.addEventListener("mousemove", ref.drag, false);
      }, 500)
    },
    Resize() {
      if (this.$screen.xs || this.$screen.sm) {
        this.FecharMenu();
      } else {
        this.AbrirMenu();
      }
      this.Recolher(false)
    },
    dragStart(e) {
      if (this.util.IsCompact()) {
        if (e.type === "touchstart") {
          this.initialX = e.touches[0].clientX - this.xOffset;
          this.realStartX = e.touches[0].clientX
        } else {
          this.initialX = e.clientX - this.xOffset;
          this.realStartX = e.clientX
        }
        this.animated = false;
        this.timeStart = new Date()
        this.active = this.initialX <= this.width;
      }
    },
    dragEnd() {
      this.animated = true;
      this.dragTime = new Date() - this.timeStart
      this.initialX = this.currentX;
      if (this.active)
        setTimeout(() => {
          this.validarPosicaoFinal()
        })
      this.active = false;
    },
    drag(e) {
      if (this.active) {
        if (e.type === "touchmove") {
          this.currentX = e.touches[0].clientX - this.initialX;
          this.realEndX = e.touches[0].clientX
        } else {
          this.currentX = e.clientX - this.initialX;
          this.realEndX = e.clientX
        }
        this.xOffset = this.currentX;
        this.direcao = this.realEndX > this.realStartX ? '>' : '<'
        this.validarPosicao()
        this.setTranslate(this.currentX);
      }
    },
    validarPosicao() {
      if (this.currentX > 0) {
        this.currentX = 0
        this.xOffset = 0
      } else if (this.offset().left < (this.width * -1)) {
        setTimeout(() => {
          this.fecharModal()
        }, 300)
      }
    },
    validarPosicaoFinal() {
      if (this.dragTime < 300 && this.direcao === '>') {
        this.AbrirMenu()
      } else if (this.dragTime < 300 && this.direcao === '<') {
        this.FecharMenu()
      } else if (this.offset().left > (this.width / 2 * -1)) {
        this.AbrirMenu()
      } else {
        this.FecharMenu()
      }
    },
    Recolher(val) {
      if (this.$refs.menuLateral) {
        this.$refs.menuLateral.recolher = val ?? !this.$refs.menuLateral.recolher;
        this.isOpen = !this.$refs.menuLateral.recolher
        this.EmitChange();
      }
    },
    AbrirMenu() {
      this.setTranslate(0);
      this.isOpen = true;
      this.EmitChange();
      this.ignoreClickout = true;
      setTimeout(() => {
        this.ignoreClickout = false;
      }, 500)
    },
    FecharMenu() {
      if (!this.ignoreClickout) {
        let ref = this
        let fn = function (t) {
          setTimeout(() => {
            if (ref.$refs.DragContainerMenu) {
              let v = ref.$refs.DragContainerMenu.offsetWidth;
              v -= 10;
              v *= -1;
              ref.setTranslate(v);
              ref.isOpen = false;
              ref.EmitChange();
            }
          }, t)
        }
        fn(this.animated ? 0 : 100)
      }
    },
    EmitChange() {
      this.$emit('change', this.isOpen);
    },
    offset() {
      var rect = this.dragItem.getBoundingClientRect(),
          scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
    },
    setTranslate(xPos) {
      this.currentX = xPos
      this.xOffset = xPos
      this.dragItem.style.transform = "translate3d(" + xPos + "px, " + 0 + "px, 0)";
    }
  },
  computed: {
    dragItem() {
      return this.$refs.DragContainerMenu;
    },
    container() {
      return document.getElementsByTagName('html')[0]
    },
    width() {
      try {
        return this.dragItem.offsetWidth
      } catch {
        return 0
      }
    }
  }
}
</script>

<style scoped>
.menu-container {
  padding-right: 15px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 998;
  width: 260px;
}

@media screen and (min-width: 992px) {
  .menu-container {
    width: fit-content;
  }
}

.animated-menu-item {
  transition: transform .3s;
}
</style>
