import PostService from './PostService'

const KEY_LANGUAGE = '';
export default class FinanceiroService {
    constructor(source) {
        this.source = source
        this.postService = new PostService(this.source, true)
    }

    Get(filtro, callback, callbackErro) {
        this.postService.Post('LanguageResource/Content?lan=' + filtro, null, callback, callbackErro)
    }

    GetCurrentLanguage() {
        let result = localStorage.getItem(KEY_LANGUAGE)
        if(!result)
            this.SetCurrentLanguage('pt-BR')
    }

    SetCurrentLanguage(lan) {
        localStorage.setItem(KEY_LANGUAGE, lan)
    }
}
