<template>
  <transition enter-active-class="animated-400 fadeIn" leave-active-class="animated-400 fadeOut"
    @enter="StartWrapperPage">
    <el-row v-show="showLoginPage" v-if="!showFormCadastro" class="div-login" v-loading="msgLoading"
      :element-loading-text="msgLoading" element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-col :md="14" class="full-height hideOnlyMobile">
        <div v-bind:class="{
          'div-login-back-a': isAfiliadoTrivelo,
          'div-login-back-b': isAfiliadoMoblix,
          'div-login-back': !isAfiliadoTrivelo || !isAfiliadoMoblix,
          'full-height': true
        }">
          <div class="mask mask-1"></div>
          <div class="bg-darker has-mask has-mask-1 h-100 d-flex flex-column justify-content-end card-body">
            <div class="mt-4">
              <div class="h1 title mb-3" style="color:white">Mais reservas, <br> clientes mais felizes.</div>
              <p style="color:white; margin-bottom: 60px; width: 80%;">Descubra como a Moblix está revolucionando o
                comércio eletrônico no mercado de turismo. Passagens aéreas, hotéis, pacotes, experiências turísticas,
                ingressos e muito mais. Você cria sua loja online e começa a vender no mesmo dia.</p>
            </div>
            <div class="avatar-login">
              <div class="media-login media-middle media-sm media-circle border-white"> <img
                  src="../../assets/img/avatar/avatar1.png" alt="" class="image-placeholder"> </div>
              <div class="media-login media-middle media-sm media-circle border-white media-overlap"> <img
                  src="../../assets/img/avatar/avatar2.png" alt="" class="image-placeholder"> </div>
              <div class="media-login media-middle media-sm media-circle border-white media-overlap"> <img
                  src="../../assets/img/avatar/avatar3.png" alt="" class="image-placeholder"> </div>
              <div class="media-login media-middle media-sm media-circle border-white media-overlap"> <img
                  src="../../assets/img/avatar/avatar6.png" alt="" class="image-placeholder"> </div>
              <div class="media-login media-middle media-sm media-circle border-white media-overlap"> <img
                  src="../../assets/img/avatar/avatar5.png" alt="" class="image-placeholder"> </div>
              <div class="media-login media-middle media-sm media-circle border-white media-overlap"> <img
                  src="../../assets/img/avatar/avatar4.png" alt="" class="image-placeholder"> </div>
            </div>
            <p class="small mt-3" style="color:white">Milhares de pessoas e empresas utilizam a Moblix, agora é a sua
              vez.
            </p>
          </div>
        </div>
      </el-col>
      <el-col id="colFormLogin" :md="10" :sm="24" class="col-form-login full-height">
        <div class="wrapper-parent">
          <div ref="wrapperPage" class="wrapper-page">
            <transition enter-active-class="animated fadeInDown">
              <div class="text-center" v-show="showLogo">
                <a href="#/login">
                  <img class="logo-position" height="130" src="../../assets/img/logo-moblix_azul.svg" />
                </a>
              </div>
            </transition>
            <transition enter-active-class="animated-200 fadeIn" leave-active-class="animated-200 fadeOut">
              <form-login v-show="showFormLogin" @exibirLembrarSenha="transitionForms(1)"
                @LoginSuccess="LoginRealizado()">
              </form-login>
            </transition>

            <transition enter-active-class="animated-200 fadeIn" leave-active-class="animated-200 fadeOut">
              <form-lembrar-senha v-show="lembrarSenha" @VoltarLogin="transitionForms(2)"></form-lembrar-senha>
            </transition>

            <transition enter-active-class="animated zoomIn" leave-active-class="animated-200 fadeOut">
              <form-mudar-senha v-show="Hash" :hash="Hash" @Voltar="fecharFormMudarSenha()">
              </form-mudar-senha>
            </transition>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row v-else>
      <transition enter-active-class="animated fadeIn" leave-active-class="animated-100 fadeOut">
        <novo-parceiro @setMsgLoading="msgLoading = $event" @LoginSuccess="LoginRealizado()"></novo-parceiro>
      </transition>
    </el-row>
  </transition>
</template>
<script>
import FormularioLogin from './FormularioLogin'
import FormularioLembrarSenha from './FormularioLembrarSenha'
import FormularioMudarSenha from './FormularioMudarSenha'
import LoginService from '../../services/LoginService'
import NovoParceiro from "@/components/Login/NovoParceiro";

export default {
  name: 'Login',
  data() {
    return {
      showLogo: false,
      Hash: null,
      showLoginPage: false,
      showFormLogin: false,
      showFormCadastro: false,
      lembrarSenha: false,
      startAnimation: false,
      msgLoading: '',
      isAfiliadoTrivelo: false,
      isAfiliadoMoblix: false,
      fixedHeight: 520
    }
  },
  created() {
    localStorage.clear()
    sessionStorage.clear()
    this.loginService = new LoginService(this)
    this.isAfiliadoTrivelo = this.getIsAfiliadoTrivelo()
    this.isAfiliadoMoblix = this.getIsAfiliadoMoblix()
  },
  destroyed() {
    window.removeEventListener('resize', this.CalcularTopoWrapper)
  },
  mounted() {
    this.showLoginPage = true
    window.addEventListener('resize', this.CalcularTopoWrapper)
  },
  components: {
    'form-login': FormularioLogin,
    'form-lembrar-senha': FormularioLembrarSenha,
    'form-mudar-senha': FormularioMudarSenha,
    'novo-parceiro': NovoParceiro
  },
  watch: {
    $route() {
    }
  },
  methods: {
    CalcularTopoWrapper() {
      let centro = window.innerHeight / 2;
      let topo = centro - (this.fixedHeight / 2)
      topo += 'px';
      this.$refs.wrapperPage.style.setProperty("margin-top", topo);
    },
    StartWrapperPage() {
      this.$refs.wrapperPage.style.height = this.fixedHeight + 'px'
      this.CalcularTopoWrapper();
      this.StartShowLogin();
    },
    StartShowLogin() {
      this.showLogo = true
      if (this.$route.params.Hash) {
        console.log("Iniciando procedimento de recuperação de senha");
        this.showFormLogin = false
        this.showFormCadastro = false
        console.log("Entrando em ValidarHash");
        this.ValidarHash()
      } else if (this.$route.name === 'Cadastro') {
        this.showFormLogin = false
        this.showFormCadastro = true
      } else {
        this.showFormLogin = true
        this.showFormCadastro = false
      }
    },
    ValidarHash() {
      this.loginService.ValidarHash(this.$route.params.Hash, (res) => {
        if (res.Data[0]) {
          console.log("Usuário encontrado!")
          this.showFormLogin = false
          setTimeout(() => {
            this.Hash = this.$route.params.Hash
          }, 1000)
        } else {
          console.error("Verificação do Hash invaído: ", res)
          this.showFormLogin = true
          this.$message({
            message: 'Recuperação de senha falhou. Verifique se o link ainda está válido',
            type: 'error'
          })
        }
      })
    },
    transitionForms(option) {
      let ref = this
      if (option === 1) {
        this.showFormLogin = false
        setTimeout(() => {
          ref.lembrarSenha = true
        }, 420)
      } else if (option === 2) {
        this.lembrarSenha = false
        setTimeout(() => {
          ref.showFormLogin = true
        }, 420)
      }
    },
    fecharFormMudarSenha() {
      this.Hash = null
      let ref = this
      setTimeout(() => {
        ref.showFormLogin = true
      }, 420)
    },
    LoginRealizado() {
      this.startAnimation = false
      this.$emit('LoginSuccess')
      this.showLoginPage = false
    },
  },
  computed: {
  }
}
</script>

<style scoped>
.div-plano-fundo img {
  width: 100%;
  height: 100%;
}

.texto {
  position: absolute;
  top: 35%;
  left: 30%;
  z-index: 2;
  width: 30%;
}

.texto h1 {
  font-size: 40px;
  font-weight: bold;
}

.texto h2 {
  font-size: 20px;
}

.text-subtitle {
  color: #94a3b8 !important;
}


@media screen and (max-width: 1850px) {
  .texto {
    left: 20%;
    width: 40%;
  }
}

@media screen and (max-width: 990px) {
  .texto {
    display: none;
  }
}

@keyframes animeBackground {
  0% {
    background: #ffffff00;
  }

  50% {
    background: #ffffff00;
  }

  100% {
    background: #ffffff30;
  }
}


.content-link {
  padding: 5px 0px;
}

.input-group-text {
  background: #ffffff82;
  border-right: 2px solid #b0b0b0;
}

.ipt-control {
  background: white;
  color: black;
  font-weight: 600;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.span-erro-login {
  position: relative;
  font-weight: bold;
}

.span-erro-login span {
  position: relative;
  bottom: 6px;
  left: 7px;
}

.div-login {
  height: 100%;
  position: fixed;
  width: 100%;
}

.logo-position {
  height: 50px;
  margin-top: 0px !important;
}

.div-login-back {
  background-color: var(--primary-color);
  /*background-image: url('../../assets/img/landscape/Traveling.png');*/
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.div-login-back-a {
  background-image: url('../../assets/img/landscape/land-afiliado.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.div-login-back-b {
  background-image: url('../../assets/img/landscape/space-rocket.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.full-height {
  height: 100vh !important;
}

.col-form-login {
  background: #fff;
}

.col-form-login::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.col-form-login::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}


.wrapper-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
}

.wrapper-page {
  background: #fff;
  border-radius: 6px;
  padding: 40px;
  width: 450px;
}

.wrapper-page img {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mask-1,
.mask-2 {
  background-size: cover;
  left: 0;
  top: 0;
}

.mask-1 {
  background-image: url(../../assets/img/avatar/mask1.png);
  height: 200px;
  width: 504px;
}

.mask {
  position: absolute;
}

@media screen and (max-width: 992px) {
  .col-form-login {
    background-image: url('../../assets/img/landscape/auth-bg-3.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: #757575 2px 3px 14px;
  }
}

.media-sm {
  font-size: .625rem;
  height: 1.875rem;
  width: 1.875rem;
}

.media-middle {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.media-circle {
  border-radius: 50rem;
}

.media-circle {
  border-radius: 50rem;
}

.media-login {
  border-radius: 0.25rem;
  flex-shrink: 0;
  font-size: .9166666667rem;
  height: 3.5rem;
  width: 3.5rem;
}

.border-white {
  border-color: white !important;
  border-radius: 50rem;
}

.media-login img {
  border-radius: 50rem;
}

img {
  max-width: 100%;
}

img,
svg {
  vertical-align: middle;
}

.has-mask-1 {
  padding-top: 200px;
}

.has-mask {
  position: relative;
}

.bg-darker {
  --bs-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-darker-rgb), var(--bs-bg-opacity)) !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.card-body {
  padding: 5.25rem !important;
}

.media-overlap {
  margin-left: -10px;
}

.image-placeholder {
  width: 3.5rem;
  height: 3.5rem;
}
</style>
