export default class LocalizacaoService {
  Get (propName) {
    let strItem = localStorage.getItem(propName)
    if (strItem) {
      let obj = JSON.parse(strItem)
      obj.expirationDate = new Date(obj.expirationDate)
      if (obj.expirationDate > new Date()) {
        return obj.value
      } else this.Delete(propName)
    }
  }

  Set (propName, value, minutes) {
    let expirationDate
    if (!minutes) {
      // final do dia
      expirationDate = new Date(new Date(new Date(new Date().setHours(23)).setMinutes(59)).setSeconds(59))
    } else {
      expirationDate = new Date(new Date().setMinutes(new Date().getMinutes() + minutes))
    }
    let obj = {
      value: value,
      expirationDate: expirationDate.getTime()
    }
    localStorage.setItem(propName, JSON.stringify(obj))
  }

  Delete (propName) {
    localStorage.removeItem(propName)
  }
}
