<template>
    <div class="divMudarSenha">
      <div class="form-group row" v-show="!sucessoRq">
        <div class="col-12">
          <div class="input-group">
            <label>Informe uma nova senha</label>
            <el-input type="password"
                   v-model="rq.NovaSenha"
                   placeholder="Nova senha"></el-input>
          </div>
        </div>
      </div>

      <div class="form-group row" v-show="!sucessoRq">
        <div class="col-12">
          <div class="input-group">
            <label>Confirme a senha</label>
            <el-input type="password" v-model="rq.RepeteSenha" placeholder="Repetir senha"></el-input>
          </div>
        </div>
      </div>
      <label-erro-login :msg="msgErro" :destacar="destacarErro" ></label-erro-login>
      <b-row v-show="!sucessoRq">
        <b-col>
          <div class="form-group text-right m-t-20">
            <el-button @click="Enviar()" type="primary" :loading="carregando">
              Mudar senha
              <i class="el-icon-check el-icon-right"></i>
            </el-button>
          </div>
        </b-col>
      </b-row>
    </div>
</template>

<script>
  import LoginService from '../../services/LoginService'
  import LabelErroLogin from './LabelErroLogin'

  export default {
    name: 'FormularioMudarSenha',
    props: ['hash'],
    data () {
      return {
        rq: {
          NovaSenha: '',
          RepeteSenha: ''
        },
        msgErro: '',
        destacarErro: false,
        sucessoRq: false,
        carregando: false
      }
    },
    components: {
      'label-erro-login': LabelErroLogin
    },
    created () {
      this.loginService = new LoginService(this)
    },
    methods: {
      Enviar () {
        if(this.rq.NovaSenha) {
          this.carregando = true;
          this.rq.Hash = this.hash
          this.loginService.MudarSenha(this.rq, this.callback, this.callbackErro)
        }
      },
      Voltar () {
        this.$emit('Voltar')
      },
      callback () {
        this.carregando = false;
        this.msgErro = false
        this.sucessoRq = true
        this.$message({
          message: 'Senha alterada com sucesso!',
          type: 'success'
        })
        this.Voltar()
      },
      callbackErro(res) {
        this.carregando = false;
        console.log('Erro LEMBRAR SENHA')
        console.log(res)
        if (this.msgErro != null) {
          this.destacarErro = true
          setTimeout(() => {
            this.destacarErro = false
          }, 1000)
        }
        this.msgErro = res.Erro.Message
      }
    }
  }
</script>

<style scoped>
  .divMudarSenha {
    margin-top: 30px;
  }
</style>
