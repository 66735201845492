export default class DateTimeService {
    FormatDate(datetime, padrao, setOntemHoje, separator) {
        if (datetime) {
            if (!(datetime instanceof Date)) datetime = this.ConvertDataPadrao(datetime)
            let d = datetime.getDate()
            let m = datetime.getMonth() + 1
            let y = datetime.getFullYear()
            let text = ''
            if (setOntemHoje) {
                let target = new Date(y, m - 1, d)
                let result = this.DateDiff(new Date(), target)
                if (result.Anos === 0 && result.Meses === 0 && result.Dias === 0) {
                    text = ' - Hoje'
                } else if (result.Anos === 0 && result.Meses === 0 && result.Dias === 1) {
                    text = ' - Ontem'
                }
            }
            let s = separator ? separator : '/'
            if (padrao) {
                return this.PrependZero(d) + s + this.PrependZero(m) + s + this.PrependZero(y) + text
            } else {
                return this.PrependZero(y) + s + this.PrependZero(m) + s + this.PrependZero(d) + text
            }
        }
    }

    FormatDiaMes(datetime) {
        if (datetime) {
            if (!(datetime instanceof Date)) datetime = this.ConvertDataPadrao(datetime)
            let d = datetime.getDate()
            let m = datetime.getMonth() + 1
            return this.PrependZero(d) + '/' + this.PrependZero(m)
        }
    }

    ConvertDataPadrao(dateStr) {
        try {
            if (dateStr) {
                let data = dateStr.split('T')[0].split('-')
                let horario = dateStr.split('T')[1].split(':')
                let ano = parseInt(data[0])
                let mes = parseInt(data[1]) - 1
                let dia = parseInt(data[2])
                let hora = parseInt(horario[0])
                let minuto = parseInt(horario[1])
                let segundo = parseInt(horario[2].split('-')[0])
                return new Date(ano, mes, dia, hora, minuto, segundo)
            }
        } catch (e) {
            console.log('Erro ao converter DATA')
            console.log(e)
        }
    }

    ConvertDataString(dateStr) {
        let data = dateStr.split('/')
        let ano = parseInt(data[2]),
            mes = parseInt(data[1]) - 1,
            dia = parseInt(data[0])
        return new Date(ano, mes, dia)
    }

    FormatDateTime(datetime, seconds) {
        if (datetime) {
            if (!(datetime instanceof Date)) datetime = this.ConvertDataPadrao(datetime)
            let dateStr = this.FormatDate(datetime, true)
            let h = datetime.getHours()
            let m = datetime.getMinutes()
            let s = datetime.getSeconds()
            let timeStr = this.PrependZero(h) + ':' + this.PrependZero(m) + (seconds ? ':' + this.PrependZero(s) : '')
            return dateStr + ' ' + timeStr
        }
    }

    PrependZero(value) {
        if (value < 10) value = '0' + value
        return value
    }

    DateDiff(date1, date2) {
        // eslint-disable-next-line
        let y1 = date1.getFullYear(), m1 = date1.getMonth(), d1 = date1.getDate()
        // eslint-disable-next-line
        let y2 = date2.getFullYear(), m2 = date2.getMonth(), d2 = date2.getDate()
        if (d1 < d2) {
            m1--
            d1 += new Date(y2, m2, 0).getDate()
        }
        if (m1 < m2) {
            y1--
            m1 += 12
        }
        return {
            Dias: d1 - d2,
            Meses: m1 - m2,
            Anos: y1 - y2
        }
    }

    LastDayMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }

    AddHour(date, h) {
        date.setTime(date.getTime() + (h * 60 * 60 * 1000));
        return date;
    }

    AddMinutes(date, m) {
        return new Date(date.setMinutes(date.getMinutes() + m))
    }

    OnlyDate(dateTime) {
        let y = dateTime.getFullYear(),
            m = dateTime.getMonth(),
            d = dateTime.getDate()
        return new Date(y, m, d)
    }
}
