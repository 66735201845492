import PostService from './PostService'
import UtilService from './UtilService'

export default class LojaService {
  constructor(source) {
    this.source = source
    this.util = new UtilService(source)
    this.postService = new PostService(this.source, true)
  }

  NovaAgenciaSimplificada(parceiro, callback, callbackErro) {
    this.postService.Post('Loja/NovaAgenciaSimplificada', parceiro, callback, callbackErro, true)
  }

  Migrar(rq, callback, callbackErro) {
    this.postService.Post('Loja/Migrar', rq, callback, callbackErro, true)
  }

  ListarIdiomasDisponiveis(callback, callbackErro) {
    this.postService.Post('Loja/ListarIdiomasDisponiveis', null, callback, callbackErro, true)
  }

  PegarCupomAssinatura(rq, callback, callbackErro) {
    this.postService.Post('Loja/PegarCupomAssinatura', rq, callback, callbackErro, true)
  }
}
