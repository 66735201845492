import PostService from './PostService'

export default class BancoService {
  constructor (source) {
    this.source = source
    this.postService = new PostService(this.source, true)
  }

  Listar (callback, callbackErro) {
    this.postService.Post('Banco/Listar', null, callback, callbackErro, true)
  }

  ListarCreate (callback, callbackErro) {
    this.postService.Post('Banco/ListarCreate', null, callback, callbackErro, true)
  }
}
