import Vue from "vue";
import Router from "vue-router";

import Login from "@/components/Login/Login";
import ProxyManager from "@/components/Proxy/ProxyCia.vue";
const CardPesquisa = () => import("@/components/CardPesquisa/CardPesquisa");
const ContaCategorias = () =>
  import("@/components/Financeiro/Categorias/ContainerCategoria.vue");
const HotelConfig = () => import("@/components/MenuHotel/HotelConfig");
const Newsletter = () => import("@/components/Newsletter/Newsletter");
const Integracoes = () =>
  import("@/components/Parceiros/ConfigAgencia/Integracoes");
const Faturas = () => import("@/components/Parceiros/Faturas");
const ListaParceiros = () => import("@/components/Parceiros/ListaParceiros");
const PesquisaVoos = () => import("@/components/PesquisaVoos/PesquisaVoos");
const PessoaListar = () => import("@/components/Pessoa/PessoaListar");
const MigrarPlano = () => import("@/components/Planos/Pagamento/MigrarPlano");
const ProxyConfig = () => import("@/components/Proxy/Config");
const Push = () => import("@/components/Push/Push");
const Qrcode = () => import("@/components/Qrcode/Qrcode");
const Setup = () => import("@/components/Setup/Setup");
const BI = () => import("../components/Administracao/BI");
const PesquisaAPI = () => import("../components/Administracao/PesquisaAPI");
const SplitAdmin = () => import("../components/Administracao/Split/SplitAdmin");
const BlogTrivelo = () => import("../components/BlogPomocoesPI/BlogTrivelo");
const PromocoesAdmin = () =>
  import("../components/BlogPomocoesPI/PromocoesAdmin");
const MigrouPlano = () => import("@/components/Planos/Pagamento/MigrouPlano");

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "",
      name: "Default",
      component: () => import("@/components/Home/Home"),
      meta: {
        hideHeader: true,
      },
    },
    {
      path: "/Login",
      name: "Login",
      component: () =>
        import(/* webpackChunkName: "auth" */ "@/components/Login/Login"),
    },
    {
      path: "/Login/:Hash",
      name: "LoginHash",
      component: Login,
    },
    {
      path: "/monte-sua-loja",
      name: "Cadastro",
      component: () =>
        import(
          /* webpackChunkName: "cadastro" */ "@/components/Login/NovoParceiro"
        ),
    },
    {
      path: "/ListaPessoa",
      name: "Lista de clientes",
      component: () =>
        import(
          /* webpackChunkName: "clientes" */ "@/components/Clientes/ListaClientes"
        ),
      meta: {
        description: "Gerencie seus clientes",
      },
    },
    {
      path: "/Grupos",
      name: "Grupos de usuários",
      component: () =>
        import(
          /* webpackChunkName: "clientes" */ "@/components/Clientes/Grupos/GrupoPessoas"
        ),
      meta: {
        description: "Organize os seus usuários em grupos",
        showBack: true,
      },
    },
    {
      path: "/Grupos/:id",
      name: "Pessoas do grupo",
      component: () =>
        import(
          /* webpackChunkName: "clientes" */ "@/components/Clientes/Grupos/GrupoPessoas.vue"
        ),
    },
    {
      path: "/VerCliente/:id",
      name: "Dados do cliente",
      component: () =>
        import(
          /* webpackChunkName: "clientes" */ "@/components/Clientes/VerCliente/VerCliente.vue"
        ),
      meta: {
        description: "Gerencie seus clientes",
        showBack: true,
      },
    },
    {
      path: "/VerUsuario/:id",
      name: "Dados do usuário",
      component: () =>
        import(
          /* webpackChunkName: "clientes" */ "@/components/Clientes/VerCliente/VerCliente.vue"
        ),
      meta: {
        description: "Gerencie os usuários da agência",
        showBack: true,
      },
    },
    {
      path: "/usuario/:id",
      name: "Edição de usuário",
      component: () =>
        import(
          /* webpackChunkName: "clientes" */ "@/components/Clientes/AdicionarCliente/AdicionarCliente.vue"
        ),
      meta: {
        description: "Gerencie os dados dos seus usuários",
        showBack: true,
      },
    },
    {
      path: "/cadastro-inicial", // Rota para a página de cadastro dentro do iframe
      name: "CadastroInicial",
      component: () => import("@/components/Login/CadastroIframe.vue"), // Carrega o componente dinamicamente
      meta: {
        description: "Página de cadastro inicial para iframe",
        hideHeader: true, // Se quiser ocultar o header nessa página
      },
    },    
    {
      path: "/cliente/:id",
      name: "Edição de cliente",
      component: () =>
        import(
          /* webpackChunkName: "clientes" */ "@/components/Clientes/AdicionarCliente/AdicionarCliente.vue"
        ),
      meta: {
        description: "Gerencie os dados dos seus clientes",
        showBack: true,
      },
    },
    {
      path: "/Fornecedor/:id?",
      name: "Dados do Fornecedor",
      component: () =>
        import(
          /* webpackChunkName: "fornecedores" */ "@/components/Pessoa/Modal/Pessoa"
        ),
      props: true,
      meta: {
        description: "Informações do seu Fornecedor",
        showBack: true,
      },
    },

    {
      path: "/ListaPedidos",
      name: "Lista de pedidos",
      component: () =>
        import(
          /* webpackChunkName: "pedidos" */ "@/components/Pedidos/ListaPedidos"
        ),
      meta: {
        description: "Gerencie suas vendas",
      },
    },
    {
      path: "/ListaNegociacoes",
      name: "Negociações",
      component: () =>
        import(
          /* webpackChunkName: "pedidos" */ "@/components/Pedidos/ListaPedidos"
        ),
      meta: {
        id: "listagem-negociacao",
        description:
          "Crie oportunidades de negócios e acompanhe os orçamentos que ainda não foram convertidos em pedidos",
      },
    },
    {
      path: "/Pedido/:Id",
      name: "Dados do pedido",
      component: () =>
        import(
          /* webpackChunkName: "detalhes-pedido" */ "@/components/Pedidos/Pedido"
        ),
      meta: {
        description: "Aqui você pode acompanhar o status e detalhes do pedido",
        showBack: true,
      },
    },
    {
      path: "/Negociacao/:Id",
      name: "Dados do negócio",
      component: () =>
        import(
          /* webpackChunkName: "orcamento" */ "@/components/Pedidos/Negociacao/Negociacao"
        ),
      meta: {
        description:
          "Aqui você pode acompanhar o status e detalhes do orçamento",
        showBack: true,
      },
    },
    {
      path: "/ListaPontos",
      name: "Consultar pontos",
      component: () =>
        import(
          /* webpackChunkName: "pontos" */ "@/components/ConsultaPontos/ListaPontos"
        ),
    },
    {
      path: "/ValoresCias",
      name: "Política para a pesquisa de milhas",
      component: () =>
        import(
          /* webpackChunkName: "pontos" */ "@/components/ValoresCias/ValoresCias"
        ),
      meta: {
        description: "Defina sua política para cada programa de fidelidade/cia",
      },
    },
    {
      path: "/Parceiros",
      name: "Lista de parceiros",
      component: ListaParceiros,
    },
    {
      path: "/Agencia",
      name: "Configurações",
      component: () =>
        import(
          /* webpackChunkName: "agencia" */ "@/components/Parceiros/NewAgencia"
        ),
      props: true,
    },
    {
      path: "/Agencia/:Path",
      name: "Minha agência",
      component: () =>
        import(
          /* webpackChunkName: "agencia" */ "@/components/Parceiros/NewAgencia"
        ),
    },
    {
      path: "/Agencia/:Id/:Path",
      name: "Gerenciar parceiro",
      component: () =>
        import(
          /* webpackChunkName: "agencia" */ "@/components/Parceiros/NewAgencia"
        ),
    },
    {
      path: "/Site/:Id?/:item?",
      name: "Temas",
      component: () => import("@/components/Parceiros/Site"),
      meta: {
        description:
          "Veja o tema habilitado para o seu site, e navega na Biblioteca de Temas",
      },
    },
    {
      path: "/Site/:Id",
      name: "Meu site",
      component: () => import("@/components/Parceiros/Site"),
    },
    {
      path: "/BibliotecaTemas",
      name: "Biblioteca de temas",
      component: () =>
        import("@/components/Parceiros/ConfigSite/Temas/BibliotecaTemas"),
      meta: {
        showBack: true,
        description: "Selecione o layout mais adequeado para o seu negócio",
      },
    },
    {
      path: "/Reports/Air",
      name: "Aéreo",
      component: () => import("@/components/Relatorios/Reports/ReportAir"),
      meta: {
        showBack: false,
        description: "Relatório de pesquisas realizadas na agência",
      },
    },
    {
      path: "/Financeiro/contas-a-receber",
      name: "Contas a receber",
      component: () => import("@/components/Financeiro/Contas"),
      meta: {
        showBack: false,
        description: "Lançamentos dos recebíveis",
      },
    },
    {
      path: "/Financeiro/contas-a-pagar",
      name: "Contas a pagar",
      component: () => import("@/components/Financeiro/Contas"),
      meta: {
        showBack: false,
        description: "Lançamentos dos pagamentos",
      },
    },
    {
      path: "/Financeiro/contas-a-receber/:id",
      name: "Receita",
      component: () =>
        import("@/components/Financeiro/Modal/FormLancamento.vue"),
      meta: {
        showBack: true,
        description: "Cadastro de lançamentos de contas a receber",
        tipoLancamento: "R",
      },
    },
    {
      path: "/Financeiro/contas-a-pagar/:id",
      name: "Despesa",
      component: () =>
        import("@/components/Financeiro/Modal/FormLancamento.vue"),
      meta: {
        showBack: true,
        description: "Cadastro de lançamentos de contas a pagar",
        tipoLancamento: "P",
      },
    },
    {
      path: "/ListaFornecedor",
      name: "Lista de Fornecedores",
      component: PessoaListar,
      meta: {
        description: "Aqui você consulta todos os seus Fornecedores",
      },
    },
    {
      path: "/Financeiro/Categoria",
      name: "Categorias de Receitas e Despesas",
      component: ContaCategorias,
    },
    {
      path: "/ListaCupons",
      name: "Gerencie seus cupons",
      component: () =>
        import(
          /* webpackChunkName: "cupons" */ "@/components/Cupons/ListaCupons"
        ),
      meta: {
        description: "Crie cupons de desconto para produtos específicos",
      },
    },
    {
      path: "/cupom/:id",
      name: "Dados do cupom",
      component: () =>
        import(
          /* webpackChunkName: "cupons" */ "@/components/Cupons/FormularioCupom.vue"
        ),
      meta: {
        showBack: true,
        description: "Edite os dados do seu cupom",
      },
    },
    {
      path: "/Push",
      name: "Push",
      component: Push,
    },
    {
      path: "/BlogCategorias",
      name: "Categorias do blog",
      component: () =>
        import(
          /* webpackChunkName: "conteudo" */ "@/components/Conteudo/ConteudoCategorias"
        ),
      meta: {
        showBack: true,
        description: "Adicione as categorias do seu blog",
      },
    },
    {
      path: "/ConteudoCategorias",
      name: "Categorias de conteúdo",
      component: () =>
        import(
          /* webpackChunkName: "conteudo" */ "@/components/Conteudo/ConteudoCategorias"
        ),
    },
    {
      path: "/Depoimentos",
      name: "Depoimentos",
      component: () =>
        import(
          /* webpackChunkName: "conteudo" */ "@/components/Conteudo/Conteudo"
        ),
      meta: {
        description: "Adicione em seu site o depoimento de um cliente",
      },
    },
    {
      path: "/Depoimentos/:id",
      name: "Depoimentos",
      component: () =>
        import(
          /* webpackChunkName: "conteudo" */ "@/components/Conteudo/componentes/FormConteudo/FormularioConteudo"
        ),
      meta: {
        idConteudoTipo: 3,
        description: "Adicione ou edite o depoimento de um cliente",
        showBack: true,
      },
    },
    {
      path: "/conteudos",
      name: "Conteúdo",
      component: () =>
        import(
          /* webpackChunkName: "conteudo" */ "@/components/Conteudo/Conteudo"
        ),
      meta: {
        description: "Crie artigos para serem exibidos em seu site",
      },
    },
    {
      path: "/conteudos/:id",
      name: "Detalhes do conteúdo",
      component: () =>
        import(
          /* webpackChunkName: "conteudo" */ "@/components/Conteudo/componentes/FormConteudo/FormularioConteudo"
        ),
      meta: {
        idConteudoTipo: 1,
        showBack: true,
        description: "Cadastro e edição de conteúdo",
      },
    },
    {
      path: "/Pages",
      name: "Páginas",
      component: () =>
        import(
          /* webpackChunkName: "conteudo" */ "@/components/Conteudo/Conteudo"
        ),
      meta: {
        description: "Adicione novas páginas",
      },
    },
    {
      path: "/Pages/:id",
      name: "Página",
      component: () =>
        import(
          /* webpackChunkName: "conteudo" */ "@/components/Conteudo/componentes/FormConteudo/FormularioConteudo"
        ),
      meta: {
        idConteudoTipo: 5,
        description: "Crie e edite páginas personalizadas",
        showBack: true,
      },
    },
    {
      path: "/Banners",
      name: "Banners",
      component: () =>
        import(
          /* webpackChunkName: "conteudo" */ "@/components/Conteudo/Conteudo"
        ),
    },
    {
      path: "/SplitAdmin",
      name: "Split",
      component: SplitAdmin,
    },
    {
      path: "/Produtos",
      name: "Lista de produtos",
      component: () =>
        import(
          /* webpackChunkName: "produtos" */ "@/components/Produtos/ListaProdutos"
        ),
      meta: {
        description: "Gerencie seus produtos",
      },
    },
    {
      path: "/VerProduto/:id",
      name: "Dados do produto",
      component: () =>
        import(
          /* webpackChunkName: "produtos" */ "@/components/Produtos/ViewProduto"
        ),
      meta: {
        description: "Edite os detalhes da experiência",
        showBack: true,
      },
    },
    {
      path: "/Produto/:id",
      name: "Dados do produto",
      component: () =>
        import(
          /* webpackChunkName: "produtos" */ "@/components/Produtos/Produto"
        ),
      meta: {
        description: "Edite os detalhes da experiência",
        showBack: true,
      },
    },
    {
      path: "/pacote/:id",
      name: "Dados do pacote",
      component: () =>
        import(
          /* webpackChunkName: "produtos" */ "@/components/Produtos/Produto"
        ),
      meta: {
        description: "Edite os detalhes do pacote",
        showBack: true,
      },
    },
    {
      path: "/CategoriaProduto",
      name: "Categorias de Produto",
      component: () =>
        import(
          /* webpackChunkName: "produtos" */ "@/components/Produtos/ProdutoCategorias"
        ),
    },
    {
      path: "/Gerador",
      name: "Gerador de Link",
      component: () =>
        import(
          /* webpackChunkName: "produtos" */ "../components/GeradorLink/GeradorLink"
        ),
    },
    {
      path: "/Qrcode",
      name: "QR Code",
      component: Qrcode,
      meta: {
        description:
          "Gere um QR Code para facilitar o acesso dos seus clientes aos seus produtos",
      },
    },
    {
      path: "/Planner/Planner",
      name: "Planeje.ai",
      component: () =>
        import(
          /* webpackChunkName: "planner" */ "@/components/Planner/Planner"
        ),
      meta: {
        description:
          "Utilize nossa Inteligência Artificial para planejar sua viagem (powered by ChatGPT)",
      },
    },
    {
      path: "/Planner/ChatGPT",
      name: "Planejamento da Viagem",
      component: () =>
        import(
          /* webpackChunkName: "planner" */ "@/components/Planner/ChatGPT"
        ),
      meta: {
        description: "Esse é o planejamento da sua viagem criado por nossa IA",
      },
    },
    {
      path: "/Calendar",
      name: "Calendário de Reservas",
      component: () =>
        import(
          /* webpackChunkName: "calendar" */ "@/components/Calendar/Calendar"
        ),
      meta: {
        description:
          "Acompanhe suas vendas em um calendário completo, e veja quando é a reserva dos seus clientes",
      },
    },

    {
      path: "/Newsletter",
      name: "Newsletter",
      component: Newsletter,
    },
    {
      path: "/Proxy",
      name: "Configuração do Proxy",
      component: ProxyConfig,
    },
    {
      path: "/ProxyManager",
      name: "Gerenciador de Proxy",
      component: ProxyManager,
      meta: {
        description: "Edite o uso dos proxies pelas cia",
        isProxyCiaEdit: true
      },
    },
    {
      path: "/Proxies",
      name: "Gerenciador de Proxy",
      component: ProxyManager,
      meta: {
        showBack: true,
        description: "Edite, adicione ou remova Proxies"
      },
    },
    {
      path: "/PesquisaAPI",
      name: "PesquisaAPI",
      component: PesquisaAPI,
    },
    {
      path: "/BI",
      name: "BI",
      component: BI,
    },
    {
      path: "/Promocoes",
      name: "Promoções",
      component: PromocoesAdmin,
    },
    {
      path: "/BlogTrivelo",
      name: "Blog Trivelo",
      component: BlogTrivelo,
    },
    {
      path: "/DashFinanceiro",
      name: "Dashboard financeiro",
      component: () =>
        import("@/components/DashboardFinanceiro/DashboardFinanceiro"),
    },
    {
      path: "/DashVendas",
      name: "Vendas Dashboard",
      component: () => import("@/components/DashboardVendas/DashboardVendas"),
    },
    {
      path: "/MeuPlano",
      name: " ",
      component: () => import("../components/Planos/MeuPlano"),
    },
    {
      path: "/ListaEmail",
      name: "Email marketing",
      component: () => import("../components/EmailMarketing/EmailMarketing"),
      meta: {
        description: "Envie até 2.000 e-mails por mês para seus clientes",
      },
    },
    {
      path: "/faturas",
      name: "Faturas Parceiro",
      component: Faturas,
    },
    {
      path: "/checkout/payment/:type/:cycle",
      name: "Migrando plano",
      component: MigrarPlano,
    },
    {
      path: "/checkout/payment/:type/:cycle/success",
      name: "Migrou de plano",
      component: MigrouPlano,
    },
    {
      path: "/Integracoes",
      name: "Integrações",
      component: Integracoes,
    },
    {
      path: "/ConfiguracaoPesquisa",
      name: "Configuração de pesquisa",
      component: PesquisaVoos,
    },
    {
      path: "/Afiliado/Acesso",
      name: "Acessos",
      component: () => import("@/components/Afiliado/Acesso"),
    },
    {
      path: "/Afiliado/Sobre",
      name: "Sobre Afiliados",
      component: () => import("@/components/Afiliado/SobreAfiliados"),
    },
    {
      path: "/Afiliado/Parceiros",
      name: "Programa de parceiros Moblix",
      component: () => import("@/components/Afiliado/AfiliadosMoblix"),
      meta: {
        description: "Como você pode receber comissões na Moblix",
      },
    },
    {
      path: "/Afiliado/TraquearUrl",
      name: "Traquear Url",
      component: () => import("@/components/Afiliado/TraquearUrl"),
    },
    {
      path: "/Aplicativos",
      name: "Aplicativos",
      component: () => import("@/components/Aplicativos/Aplicativos"),
      meta: {
        description: "Potencialize a sua loja adicionando novos APPs",
      },
    },
    {
      path: "/Feedback",
      name: "Pesquisa de satisfação",
      component: () => import("@/components/layout/PesquisaSatisfacao"),
      meta: {
        description:
          "Responda essa breve pesquisa e nos ajude a melhorar os nossos serviços.",
      },
    },
    {
      path: "/cardPesquisa",
      name: "Card Pesquisa",
      component: CardPesquisa,
    },
    {
      path: "/setup",
      name: "Setup",
      component: Setup,
    },
    {
      path: "/Cartoes/ListaCartoes",
      name: "Lista de cartões",
      component: () =>
        import(
          /* webpackChunkName: "cartoes" */ "@/components/Cartoes/ListaCartoes"
        ),
    },
    {
      path: "/Cartoes/Relatorios",
      name: "Relatórios Cartões",
      component: () =>
        import(
          /* webpackChunkName: "cartoes" */ "@/components/Cartoes/Relatorios/RelatoriosCartoes"
        ),
    },
    {
      path: "/Cartoes/Configuracoes",
      name: "Configurações",
      component: () =>
        import(
          /* webpackChunkName: "cartoes" */ "@/components/Cartoes/Configuracoes"
        ),
    },
    {
      path: "/HotelConfig",
      name: "Configurações da pesquisa de hotéis",
      component: HotelConfig,
    },
    {
      path: "/ExperienciaMarketPlace",
      name: "Marketplace de experiências",
      component: () => import("@/components/Marketplace/Experiencia"),
      meta: {
        description:
          "Habilite fornecedores para revender os seus produtos no seu site",
      },
    },
    {
      path: "/DetalheExperienciaMarketplace",
      name: "Detalhes do Fornecedor (Seller)",
      component: () => import("@/components/Marketplace/ExperienciaDetalhe"),
      meta: {
        showBack: true,
        description:
          "Visualize os dados do Fornecedor e habilite o produto que deseja vender em seu site",
      },
      props: true,
    },
    {
      path: "/MarketPlace",
      name: "Marketplace",
      component: () => import("@/components/Marketplace/MarketPlace"),
    },
    {
      path: "/Trivelo",
      name: "Trivelo",
      component: () =>
        import(
          /* webpackChunkName: "trivelo" */ "@/components/Trivelo/Trivelo"
        ),
    },
    {
      path: "/Bancorbras",
      name: "Bancorbras",
      component: () =>
        import(
          /* webpackChunkName: "bancorbras" */ "@/components/Bancorbras/Bancorbras"
        ),
    },
    {
      path: "/googlettd",
      name: "Google",
      component: () =>
        import(/* webpackChunkName: "google" */ "@/components/Google/Google"),
    },
    {
      path: "/Headless",
      name: "Editar Código",
      component: () =>
        import(
          /* webpackChunkName: "headless" */ "@/components/Headless/HeadlessMain"
        ),
      meta: {
        showBack: true,
        description: "Edite o código fonte do seu site",
      },
    },
  ],
});
