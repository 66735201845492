<template>
  <div>
    <transition
        enter-active-class="animated slideInDown"
        leave-active-class="slide-out-bottom"
        @after-enter="$emit('done')">
      <el-row v-if="show && UsuarioLogado" class="container-header"
              v-bind:class="{ 'container-header-min': !MenuOpen, 'shadow-div-header' : scrolled }">
        <el-col :xs="6" :span="12" class="container-flex">
          <span class="a-expand-header show-starting-992" @click="showLeftMenu()">
            <i class="el-icon-d-arrow-left icon-menu" v-bind:style="`transform: rotate(${MenuOpen ? 0 : 180}deg);`"></i>
          </span>
          <div class="hide-starting-992">
            <el-button type="text" class="btn-icon-menu" icon="el-icon-menu" @click="showLeftMenu()"/>
            <img class="img-logo-x" src="@/assets/img/logo-x-azul.svg" alt="Moblix"/>
          </div>
          <search-field v-if="UsuarioLogado" class="search-container"/>
        </el-col>
        <el-col :xs="18" :span="12">
          <el-row class="header-container-right text-right">
            <div class="icon-container">
              <el-tooltip class="box-item" effect="dark" content="Ver meu site" placement="bottom-start">
                <a @click="VerSite()" target="_blank" class="icon-top icon-top-a">
                  <icon class="i-icon" name="external-link-alt"></icon>
                </a>
              </el-tooltip>
              <botao-add v-if="UsuarioLogado.IdPermissao === 1 || UsuarioLogado.IdPermissao === 3" class="icon-top icon-top-a"/>
              <el-tooltip class="box-item" effect="dark" content="Outros Apps" placement="bottom-start">
                <botao-apps class="icon-top icon-top-a"/>
              </el-tooltip>
              <el-tooltip v-if="UsuarioLogado.IdPermissao === 1 || UsuarioLogado.IdPermissao === 3" class="box-item" effect="dark" content="Configurações" placement="bottom-start">
                <a href="#/Agencia" class="icon-top icon-top-a">
                  <i class="el-icon-setting"></i>
                </a>
              </el-tooltip>
              <a class="icon-top icon-top-a">
                <notification-badge/>
              </a>
              <botao-ajuda class="icon-top icon-top-a"/>
              <dropdown-usuario-atual class="icon-top icon-top-a drop-user" :usuario-logado="UsuarioLogado"/>
            </div>
          </el-row>
        </el-col>

      </el-row>
    </transition>
    <pop-up-notificacao :show="showMigrarPlano" @close="showMigrarPlano = false"></pop-up-notificacao>
  </div>
</template>

<script>
import PopUpNotificacao from "@/components/Shared/PopUpNotificacao";
import UtilService from "@/services/UtilService";
import Ripple from "vue-ripple-directive";
import ParceiroService from "../../../services/ParceiroService";
import SearchField from "../../Shared/Search/SearchField";
import BotaoAjuda from "@/components/layout/CabecalhoLayout/BotaoAjuda";
import BotaoApps from "@/components/layout/CabecalhoLayout/BotaoApps";
import BotaoAdd from "@/components/layout/CabecalhoLayout/BotaoAdd";
import DropdownUsuarioAtual from "@/components/layout/CabecalhoLayout/DropdownUsuarioAtual";
import NotificationBadge from './NotificationBadge.vue';

export default {
  name: "cabecalho",
  components: {
    DropdownUsuarioAtual,
    BotaoAjuda,
    PopUpNotificacao,
    SearchField,
    NotificationBadge,
    BotaoApps,
    BotaoAdd,
  },
  directives: {
    Ripple,
  },
  props: ["UsuarioLogado", 'show'],
  data() {
    return {
      ambienteDev: false,
      showMigrarPlano: false,
      scrolled: false
    };
  },
  created() {
    this.ambienteDev = process.env.NODE_ENV === "development";
    this.parceiroService = new ParceiroService(this);
    this.util = new UtilService(this);
  },
  methods: {
    handleScroll(event) {
      const divContent = event.target;
      if (divContent.scrollTop > 0) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
    showLeftMenu() {
      this.$parent.ShowLateralMenu();
    },
    VerSite() {
      this.parceiroService.Pegar((res) => {
        let url = res.Data[0].ParceiroDominio[0].Url
        if (res.Data[0].ParceiroDominio.find(x => x.Principal))
          url = res.Data[0].ParceiroDominio.find(x => x.Principal).Url
        window.open(`http://${url}`, "_blank");
      })
    },
    isElementPartiallyVisible(el) {
      const rect = el.getBoundingClientRect();
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

      // Verifica se a parte superior do elemento está dentro da janela e a parte inferior do elemento está acima da parte superior da janela
      return (rect.top >= 0 && rect.top < windowHeight) || (rect.bottom > 0 && rect.bottom <= windowHeight);
    }
  },
  computed: {
    MenuOpen() {
      return this.$parent.menuLateralAberto
    }
  },
  watch: {
    $route(newValue, oldValue) {
      if (oldValue.name === 'Setup') {
        const element = document.getElementById('div-cabecalho');
        if (element && !this.isElementPartiallyVisible(element)) {
          let divContent = document.getElementById('div-content')
          divContent.style.display = 'none';
          setTimeout(() => {
            divContent.style.display = 'block';
          }, 500)
        }
      }
    }
  },
};
</script>

<style scoped>
.container-header {
  border-bottom: solid 0px var(--fundo-neutro);
  height: 70px;
  transition: all .3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) {
  .container-header {
    margin-left: 250px;
  }

  .container-header-min {
    margin-left: 60px;
  }
}

.container-flex {
  display: flex;
}

.header-container-right {
  display: inline-block !important;
  width: 100%;
  text-align: right !important;
}

.header-container-right .drop-user {
  display: contents;
}

.icon-menu {
  font-size: 21px;
  margin-left: 15px;
  color: var(--text-color);
  margin-top: 10px;
}

.search-container {
  margin-left: 5px;
  width: 100%;
}

.icon-top {
  padding: 10px;
  cursor: pointer;
  color: var(--gray);
}

.icon-top i {
  font-size: 20px;
}

.icon-top .el-icon-setting {
  position: relative;
  top: 4px;
}

.icon-top-a {
  position: relative;
}

.a-expand-header {
  cursor: pointer;
}

.a-expand-header i {
  transition: all .3s linear;
}

.btn-icon-menu {
  font-size: 1.4rem;
  margin-left: 15px;
  color: var(--gray);
}

.img-logo-x {
  height: 25px;
  position: absolute;
  left: 50px;
  top: 20px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.shadow-div-header {
  box-shadow: 0px 5px 9px #6f6f6f2b;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 600px) {
  .icon-container {
    margin-top: -10px !important;
  }
}
</style>
