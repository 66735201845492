<template>
  <el-row v-loading="loading || deleting">
    <el-table :data="filteredProxies" v-if="builded && !loading" v-bind:show-summary="isEdicaoProxyPorCia"
      :summary-method="getSummaries">
      <el-table-column prop="Id" label="#" width="50"></el-table-column>
      <el-table-column prop="Name" label="Proxy" :width="!isEdicaoProxyPorCia ? '200' : ''"></el-table-column>
      <el-table-column prop="Config" label="Config" v-if="!isEdicaoProxyPorCia"></el-table-column>
      <el-table-column prop="PercentSucess" label="Taxas" align="center" v-if="isEdicaoProxyPorCia" width="auto"
        controls-position="center">
        <template slot-scope="scope">
          <grafico-acerto :id-cia="idCia" :proxy="GetProxy(scope.row.Id)" :erro-max="erroMax" />
        </template>
      </el-table-column>
      <el-table-column prop="Rpm" label="RPM" align="center" v-if="isEdicaoProxyPorCia">
        <template slot-scope="scope">
          {{ GetProp(scope.row.Id, 'Rpm') }}
        </template>
      </el-table-column>
      <el-table-column prop="AvarageTime" label="Tempo médio" align="center" v-if="isEdicaoProxyPorCia">
        <template slot-scope="scope">
          {{ GetTempoMedio(scope.row.Id) }}
        </template>
      </el-table-column>
      <el-table-column prop="ProxyCia[0].Probability" align="center" label="Tráfego %" width="200"
        v-if="isEdicaoProxyPorCia">
        <template slot-scope="scope">
          <el-input-number v-if="!intervalOn && scope.row.ProxyCia && scope.row.ProxyCia.length"
                           class="el-input-number-sm"
                           size="mini"
                           controls-position="right"
                           :disabled="intervalOn"
                           v-model="scope.row.ProxyCia[0].Probability"
                           :min="0"
                           :max="100"
                           :step="1" />
          <span v-else>{{ scope.row.ProxyCia[0].Probability }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="Ativo em" width="180" v-if="!isEdicaoProxyPorCia">
        <template slot-scope="scope">
          <el-select v-model="scope.row.ativoEm" multiple placeholder="Select" @change="setAtivos(scope.row)">
            <el-option v-for="item in cias" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="IsSubnet" label="Subnet" width="80" align="center" v-if="!isEdicaoProxyPorCia">
        <template slot-scope="scope">
          {{ scope.row.IsSubnet ? 'Sim' : 'Não' }}
        </template>
      </el-table-column>
      <el-table-column width="150" v-if="!isEdicaoProxyPorCia" align="right">
        <template slot-scope="scope">
          <el-button type="info" plain icon="el-icon-edit" size="mini" @click="Selecionar(scope.row)"></el-button>
          <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="Deletar(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <edit-proxy ref="editProxy" :proxy="Selected" v-if="Proxies.length" />
    <proxy-manager-config v-if="isEdicaoProxyPorCia" ref="pManagerConfig" :id-cia="idCia" @change="erroMax = $event" />
  </el-row>
</template>


<script>
import ProxyManagerService from "@/services/ProxyManagerService";
import EditProxy from "@/components/Proxy/EditProxy.vue";
import GraficoAcerto from "@/components/Proxy/GraficoAcerto.vue";
import ProxyManagerConfig from "@/components/Proxy/ProxyConfig.vue";

export default {
  name: "TableProxies",
  components: {
    ProxyManagerConfig,
    GraficoAcerto,
    EditProxy
  },
  props: ['idCia'],
  data() {
    return {
      erroMax: -1,
      Proxies: [],
      Selected: null,
      loading: false,
      builded: true,
      ProxyManagerConfig: [],
      timerList: null,
      timerCalcBlock: null,
      deleting: false, // Estado para o carregamento durante a exclusão
      intervalOn: false,
      cias: [{
        value: 2,
        label: 'Gol'
      }, {
        value: 3,
        label: 'Azul'
      }],
    };
  },
  created() {
    this.Listar();
  },
  mounted() {
    this.timerCalcBlock = setInterval(() => {
      this.buildTempoRestante();
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timerCalcBlock)
    this.destroyListInterval()
  },
  watch: {
    idCia() {
      this.clearData();  // Limpa os dados da tabela ao trocar de companhia
      this.Listar();
    }
  },
  methods: {
    clearData() {
      this.builded = false;  // Esconde a tabela
      this.Proxies = [];  // Limpa os dados
    },
    buildAtivosEm(data) {
      data.forEach(d => {
        d.ativoEm = d.ProxyCia.filter(x => x.Active).map(x => x.IdCia)
      })
      return data;
    },
    setAtivos(data) {
      let ativos = data.ativoEm;
      data.ProxyCia.forEach(i => {
        i.Active = ativos.includes(i.IdCia)
      })

      let ativosNaoInclusos = ativos.filter(x => !data.ProxyCia.some(y => y.IdCia === x))
      ativosNaoInclusos.forEach(idCia => {
        data.ProxyCia.push({
          IdCia: idCia,
          IdProxy: data.Id,
          Active: true,
          Probability: 0
        })
      })
    },
    buildListInterval() {
      this.intervalOn = true;
      if (!this.timerList)
        this.timerList = setInterval(() => {
          if (!this.isProxies) {
            this.Listar(true);
          } else {
            this.destroyListInterval()
            setTimeout(() => {
              this.Listar()
            }, 1000)
          }
        }, 5000) // 5 segundos
    },
    destroyListInterval() {
      this.intervalOn = false;
      clearInterval(this.timerList)
      this.timerList = null
    },
    Deletar(proxy) {
      if (!proxy.Id) {
        this.Listar();
      } else {
        this.$confirm('Deseja realmente deletar o proxy?', 'Atenção', {
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          type: 'warning'
        }).then(() => {
          this.deleting = true; // Inicia o carregamento
          this._Deletar(proxy)
        }).catch(() => {
          this.$message.info('Operação cancelada')
        });
      }
    },
    _Deletar(proxy) {
      this.proxyManagerService.Deletar(proxy.Id, this.CallbackDeletar, this.CallbackDeleteErro)
    },
    CallbackDeletar(res) {
      this.deleting = false; // Termina o carregamento
      if (res.Success) {
        this.$message.success('Proxy deletado com sucesso')
        this.Listar();
      } else {
        this.CallbackDeleteErro();
      }
    },
    CallbackDeleteErro() {
      this.deleting = false; // Termina o carregamento
      this.$message.error('Erro ao deletar');
    },
    Selecionar(proxy) {
      this.Selected = proxy
      this.$refs.editProxy.drawer = true;
    },
    Listar(ignoreLoading = false) {
      this.loading = !ignoreLoading;

      if (!this.proxyManagerService)
        this.proxyManagerService = new ProxyManagerService(this);

      this.proxyManagerService.Listar(this.isProxies ? -1 : this.idCia, this.CallbackListar);
    },
    CallbackListar(res) {
      res.Data.forEach(p => {
        p.TempoRestante = this.tempoRestante(p);
      });

      if (!this.isEdicaoProxyPorCia) {
        res.Data = this.buildAtivosEm(res.Data);
      }

      this.Proxies = res.Data;  // Atualiza os proxies com os novos dados
      this.builded = true;  // Exibe a tabela novamente
      this.loading = false;  // Desativa o estado de carregamento
    },
    Salvar() {
      if (this.isEdicaoProxyPorCia)
        this._SalvarProxyCia()
      else
        this._SalvarProxy()
    },
    _SalvarProxyCia() {
      let sum = this.Proxies.filter(x => x.ProxyCia[0].Active).reduce((a, b) => a + b.ProxyCia[0].Probability, 0)
      if (sum !== 100)
        this.$message.error('A soma das probabilidades deve ser 100%')
      else {
        this.loading = true;
        let proxyCias = []
        this.Proxies.forEach(p => {
          proxyCias.push(p.ProxyCia[0])
        })
        this.proxyManagerService.GravarProxyCia(proxyCias, this.CallbackSalvar, this.callbackErro);
        this.$refs.pManagerConfig.Gravar();
      }
    },
    _SalvarProxy() {
      this.loading = true;
      this.proxyManagerService.Gravar(this.Proxies, this.CallbackSalvar, this.callbackErro);
    },
    CallbackSalvar(res) {
      if (res.Success) {
        this.$message.success('As alterações foram salvas')
        this.loading = false;
        this.Listar();
      } else
        this.$message.error('Erro ao salvar')
    },
    CallbackErro() {
      this.loading = false;
      this.$message.error('Erro ao salvar')
    },
    rebuild() {
      this.builded = false
      this.Listar();
      setTimeout(() => {
        this.builded = true
      }, 100)
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((c, index) => {
        if (c.property === 'ProxyCia[0].Probability') {
          let total = 0;
          data.forEach(i => total += i.ProxyCia[0].Probability)
          sums[index] = total + '%';
        } else if (c.property === 'Rpm') {
          let total = 0;
          data.forEach(i => {
            if(i.ProxyTaxas.length) {
              total += i.ProxyTaxas[0].Rpm
            }
          })
          sums[index] = total;
        } else {
          sums[index] = '';
        }
      })
      return sums;
    },
    buildTempoRestante() {
      if (this.Proxies && this.Proxies.length) {
        this.Proxies.forEach(p => {
          p.TempoRestante = this.tempoRestante(p)
        })
      }
    },
    tempoRestante(i) {
      let retorno = '--'
      if (i.Blocked)
        retorno = this.calcularTempoRestante(new Date(i.BlockedIn), this.tempoBloqueio)
      return retorno
    },
    calcularTempoRestante(dataBloqueio, minutosBloqueio) {
      let agora = new Date();
      dataBloqueio.setMinutes(dataBloqueio.getMinutes() + minutosBloqueio);
      let diferenca = dataBloqueio - agora;

      // Verifica se a diferença é menor ou igual a zero
      if (diferenca <= 0) {
        return "00:00:00";
      }

      let horas = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
      let segundos = Math.floor((diferenca % (1000 * 60)) / 1000);

      return `${this.padZero(horas)}:${this.padZero(minutos)}:${this.padZero(segundos)}`;
    },
    padZero(num) {
      return num < 10 ? '0' + num : num;
    },
    GetProxy(id) {
      let retorno = this.Proxies.find(x => x.Id === id)
      return retorno;
    },
    GetTx(id) {
      let proxy = this.GetProxy(id)
      let taxas = proxy.ProxyTaxas.filter(x => x.IdCia == this.idCia)
      return taxas;
    },
    GetProp(id, prop) {
      let target = this.GetTx(id)
      if(target && target.length) {
        return target[0][prop]
      } else {
        return '--'
      }
    },
    GetPropInt(id, prop) {
      let target = this.GetTx(id)
      if(target) {
        return parseInt(target[0][prop])
      } else {
        return 0
      }
    },
    GetTempoMedio(id) {
      let target = this.GetTx(id)
      if(target && target.length) {
        let tempo = target[0].TempoMedio
        if(tempo) {
          return this.$UtilMbx.FormatMilhar(tempo)
        }
      }
      return '--'
    }
  },
  computed: {
    isEdicaoProxyPorCia() {
      return this.$route.meta.isProxyCiaEdit ?? false;
    },
    filteredProxies() {
      if (this.isEdicaoProxyPorCia) {
        return this.Proxies.filter(proxy => proxy.ProxyCia && proxy.ProxyCia.some(pcia => pcia.Active));
      } else {
        return this.Proxies;
      }
    },
    tempoBloqueio() {
      return -1;
    },
    isProxies() {
      var parent = this.$UtilMbx.GetParent(this, 'ProxyManager')
      return parent.isProxiesPath
    }
  }
};
</script>

<style scoped></style>
