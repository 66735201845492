export default class FacebookPixelService {
  constructor() {
    this.fbPixel = window['fbq']
  }

  Track(eventName, param) {
    if(process.env.NODE_ENV === 'production' && this.fbPixel)
      this.fbPixel('track', eventName, param)
  }
}
