import HttpService from "@/services/HttpService";

export default class ProxyManagerService {
    constructor(source) {
        this.source = source
        this.httpService = new HttpService(this.source, process.env.VUE_APP_URL_PROXY_MANAGER_API, true)
    }

    Listar(idCia, callback, callbackErro) {
        this.httpService.Get('Proxy?idCia=' + idCia, callback, callbackErro)
    }

    Gravar(data, callback, callbackErro) {
        this.httpService.Post('Proxy', data, callback, callbackErro)
    }

    Deletar(id, callback, callbackErro){
        this.httpService.Delete('Proxy?id=' + id, callback, callbackErro)
    }

    ListarSubnet(idProxy, callback, callbackErro) {
        this.httpService.Get('IpPool?idProxy=' + idProxy, callback, callbackErro)
    }

    GravarSubnet(data, callback, callbackErro) {
        this.httpService.Post('IpPool', data, callback, callbackErro)
    }

    GravarProxyCia(data, callback, callbackErro) {
        this.httpService.Post('ProxyCia', data, callback, callbackErro)
    }

    ListarProxyConfig(idCia, callback, callbackErro) {
        this.httpService.Get('ProxyConfig?idCia=' + idCia, callback, callbackErro)
    }

    GravarProxyConfig(data, callback, callbackErro) {
        this.httpService.Post('ProxyConfig', data, callback, callbackErro)
    }
}
