<template>
  <transition leave-active-class="animated slideOutUp">
    <div class="divLoadingToken">
      <vue-loading :spinner="'double-bounce'" :class="'animation-loading-token'"></vue-loading>
    </div>
  </transition>
</template>

<script>
import VueLoading from 'vue-simple-loading'

export default {
  name: 'LoadingToken',
  components: {
    VueLoading
  }
}
</script>

<style scoped>
.divLoadingToken {
  background: var(--primary-color);
  height: 100%;
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0;
  z-index: 99;
  text-align: center;
}

.divLoadingToken .animation-loading-token {
  top: 45%;
}
</style>
