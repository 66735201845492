<template>
  <div id="DivMenuLateral">
    <transition enter-active-class="animated-400 slideInLeft" leave-active-class="animated-200 slideOutLeft">
      <div class="div-mobile-menu" v-if="ShowMenu" v-bind:class="{ 'side-menu-collapsed': recolher }">
        <div id="sidMenuBarra" class="left side-menu barra">
          <el-menu id="elMenuLateral" class="el-menu-lateral" default-active="1" :collapse="collapseMenu"
            :unique-opened="true" :collapse-transition="false">
            <div class="container-logo">
              <a href="javascript:;" @click="$router.push('/')">
                <img v-if="!recolher" class="img-logo" height="28px" src="../../assets/img/logo-moblix_branco.svg"
                  alt="Moblix" />
                <img v-else class="img-logo" height="28px" src="../../assets/img/logo-x.png" alt="Moblix" />
              </a>
            </div>
            <div id="divContainerSubitensMenu" class="container-subitens-menu">
              <div v-for="(item, $index) in MenuItens" :key="$index">
                <div class="content-menu-item" v-if="!IsHide(item)">
                  <span class="lb-category" v-if="item.category && !recolher">{{ item.category }}
                    <span @click="item.fnClickIcon()">
                      <icon v-if="item.iconCategory" class="i-icon icon-category" :name="item.iconCategory" scale="0.8">
                      </icon>
                    </span>
                  </span>
                  <el-menu-item :index="$index.toString()" v-else-if="!item.subItens || !item.subItens.length"
                    :disabled="IsDisabled(item)" @click="AbrirPagina(item)">
                    <icon v-if="item.icon" class="i-icon icon-menu" :name="item.icon" scale="1"></icon>
                    <span>{{ item.title }}</span>
                  </el-menu-item>
                  <el-submenu :index="$index.toString()" :disabled="IsDisabled(item)" v-else>
                    <template slot="title">
                      <icon class="i-icon icon-menu" :name="item.icon" scale="1"></icon>
                      <span>{{ item.title }}</span>
                    </template>
                    <el-menu-item-group>
                      <div v-for="(subItem, $subindex) in item.subItens" :key="$index + '-' + $subindex"
                        class="content-sub-menu-item">
                        <el-menu-item :index="$index.toString() + '-' + $subindex.toString()"
                          @click="AbrirPagina(subItem, true)" :disabled="IsDisabled(subItem)">
                          <span>{{ subItem.title }}</span>
                        </el-menu-item>
                      </div>
                    </el-menu-item-group>
                  </el-submenu>
                </div>
              </div>
            </div>
          </el-menu>
          <transition>
            <help-box ref="helpBox" v-show="!recolher" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import MarketPlaceService from '../../services/MarketPlaceService'
import ParceiroService from '../../services/ParceiroService'
import UtilService from '../../services/UtilService'
import HelpBox from "@/components/layout/HelpBox";



export default {
  name: 'MenuLateral',
  components: { HelpBox },
  props: ['isLoginPage', 'pessoaLogada'],
  directives: {
    Ripple,
  },
  watch: {
    recolher(val) {
      if (val) {
        setTimeout(() => {
          this.collapseMenu = true;
        }, 300)
      } else {
        this.collapseMenu = false;
      }
    },
  },
  data() {
    // PLANOS
    // 0	Plano Profissional
    // 1	Plano Básico
    // 2	Plano API
    // 3	Plano Grátis
    // 4	Plano Avançado
    // 5	Plano Afiliado
    // 6  Básico Anual
    // 7	Profissional Anual
    // 8	Avançado Anual
    // 9  Afiliado Moblix
    // 10 Moblix Plus -

    // PERMISSOES
    // 1	Administrador
    // 3	Usuario
    // 5	AgenciaExterna
    // 6	Marketing
    // 7	Financeiro
    // 8	Vendas
    // 9	Emissão

    // PERMISSAO AGENCIA
    // PesquisaMoblix = 0,
    // PesquisaTap = 1,
    // PesquisaInterline = 2,
    // PesquisaRextur = 3,
    // IdiomasMoedas = 4,
    // WidgetBusca = 5,
    // CarteiraMilhas = 6,
    // PesquisaEsfera = 7,
    // Franqueado = 8,
    // BestBuy = 9,
    // AnaliseFraudeMbx = 10
    // Headless = 11,
    // PesquisaCopa = 12,

    return {
      permissoesAgencia: null,
      recolher: false,
      collapseMenu: false,
      MenuItens: [
        {
          key: "INICIO",
          title: "Início",
          selected: false,
          icon: "home",
          href: "/",
          permissoes: [1, 3, 6, 7, 8, 9],
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 9, 10],
        },
        {
          key: "PROGRAMA_DE_PARCEIROS",
          title: "Programa de parceiros",
          selected: false,
          icon: "user-circle",
          href: "/Afiliado/Parceiros",
          planos: [9],
        },
        {
          key: "PEDIDOS",
          title: "Pedidos",
          selected: false,
          icon: "inbox",
          href: "",
          subItens: [
            {
              key: "PEDIDOS_VENDAS",
              title: "Vendas",
              href: "/ListaPedidos",
              permissoes: [1, 3, 4, 8, 9, 10],
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
            },
            {
              key: "PEDIDOS_NEGOCIACOES",
              title: "Negociações",
              href: "/ListaNegociacoes",
              permissoes: [1, 3, 4, 8, 9, 10],
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
            }
          ],
          permissoes: [1, 3, 8, 9],
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
        },
        {
          key: "PRODUTOS",
          title: "Produtos",
          selected: false,
          icon: "route",
          href: "",
          subItens: [
            {
              key: "PRODUTOS_LISTA_PRODUTOS",
              title: "Lista de produtos",
              href: "/Produtos",
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
              permissoes: [1, 3, 8],
            },
            {
              key: "PRODUTOS_CATEGORIAS",
              title: "Categorias",
              href: "/CategoriaProduto",
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
              permissoes: [1, 3, 8],
            },
          ],
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
          permissoes: [1, 3, 8],
        },
        {
          key: "CLIENTES",
          title: "Clientes",
          selected: false,
          icon: "users",
          href: "/ListaPessoa",
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 9, 10],
          permissoes: [1, 3, 6, 7, 8],
        },
        {
          key: "APPS",
          title: "Apps",
          selected: false,
          icon: "th-large",
          href: "/Aplicativos",
          planos: [0, 1, 3, 4, 6, 7, 8, 10],
          permissoes: [1, 3, 6],
        },
        {
          key: "FINANCEIRO",
          title: "Financeiro",
          selected: false,
          icon: "money-bill-alt",
          href: "",
          subItens: [
            {
              key: "FINANCEIRO_CONTAS_PAGAR",
              title: "Contas a pagar",
              href: "/Financeiro/contas-a-pagar",
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
              permissoes: [1, 3, 7],
            },
            {
              key: "FINANCEIRO_CONTAS_RECEBER",
              title: "Contas a receber",
              href: "/Financeiro/contas-a-receber",
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
              permissoes: [1, 3, 7],
            },
            {
              key: "FINANCEIRO_ESTATISTICA",
              title: "Estatística",
              href: "/DashFinanceiro",
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
              permissoes: [1, 3, 7],
            },
            {
              key: "FINANCEIRO_CATEGORIAS",
              title: "Categorias",
              href: "/Financeiro/categoria",
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
              permissoes: [1, 3, 7],
            },
            {
              key: "FINANCEIRO_FORNECEDORES",
              title: "Fornecedores",
              href: "/ListaFornecedor",
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
              permissoes: [1, 3, 7],
            },
          ],
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
          permissoes: [1, 3, 7],
        },
        {
          key: "MARKETING",
          title: "Marketing",
          selected: false,
          icon: "bullseye",
          href: "",
          subItens: [
            {
              key: "MARKETING_CUPONS",
              title: "Cupons",
              href: "/ListaCupons",
              permissoes: [1, 3, 6],
              planos: [0, 1, 4, 6, 7, 8, 9, 10],
            },
            {
              key: "MARKETING_EMAIL",
              title: "Email",
              href: "/ListaEmail",
              permissoes: [1, 3, 6],
              planos: [0, 1, 4, 6, 7, 8, 9, 10],
            },
            {
              key: "MARKETING_PUSH",
              title: "Push",
              href: "/Push",
              permissoes: [1, 3, 6],
              planos: [0, 1, 4, 6, 7, 8, 9, 10],
            },
            {
              key: "MARKETING_GERADOR_LINK",
              title: "Gerador de link",
              href: "/Gerador",
              permissoes: [1, 3, 6],
              planos: [0, 1, 4, 6, 7, 8, 9, 10],
            },
            {
              key: "MARKETING_QR_CODE",
              title: "QR Code",
              href: "/Qrcode",
              permissoes: [1, 3, 6],
              planos: [0, 1, 4, 6, 7, 8, 9, 10],
            },
            {
              key: "MARKETING_WIDGET_BUSCA",
              title: "Widget de busca",
              href: "/cardPesquisa",
              permissoes: [1, 3, 6],
              planos: [4, 8],
              permissaoAgencia: 5,
            },
          ],
          permissoes: [1, 3, 6],
          planos: [0, 1, 4, 6, 7, 8, 9, 10],
        },
        {
          key: "DIVULGACAO",
          title: "Divulgação",
          selected: false,
          icon: "bullhorn",
          href: "/",
          subItens: [
            {
              key: "DIVULGACAO_CUPONS",
              title: "Cupons",
              href: "/ListaCupons",
              planos: [5],
            },
            {
              key: "DIVULGACAO_ESTATISTICA",
              title: "Estatística",
              href: "/afiliado/Acesso",
              planos: [5],
            },
            {
              key: "DIVULGACAO_WIDGET_BUSCA",
              title: "Widget de busca",
              ref: "/cardPesquisa",
              planos: [5],
            },
            {
              key: "DIVULGACAO_TRAQUEAR_URL",
              title: "Traquear URL",
              href: "/Afiliado/TraquearUrl",
              planos: [5],
            },
          ],
          planos: [5],
        },
        {
          key: "SOBRE_PROGRAMA",
          title: "Sobre o programa",
          selected: false,
          icon: "question-circle",
          href: "/Afiliado/Sobre",
          planos: [5],
        },
        {
          key: "ADMINISTRACAO",
          title: "Administração",
          selected: false,
          icon: "sliders-h",
          href: "/Administracao",
          hide: true,
          subItens: [
            {
              key: "ADMINISTRACAO_PARCEIRO",
              title: "Parceiros",
              href: "/Parceiros",
              permissoes: [1],
            },
            {
              key: "ADMINISTRACAO_SPLIT",
              title: "Split",
              href: "/SplitAdmin",
              permissoes: [1],
            },
            {
              key: "ADMINISTRACAO_CONTEUDOS",
              title: "Conteúdos",
              href: "/GestaoConteudos",
              permissoes: [1],
            },
            {
              key: "ADMINISTRACAO_CACHE_PESQUISA",
              title: "Cache Pesquisa",
              href: "/PesquisaAPI",
              permissoes: [1],
            },
            {
              key: "ADMINISTRACAO_PROXY",
              title: "Proxy",
              href: "/Proxy",
              permissoes: [1],
            },
            {
              key: "ADMINISTRACAO_PROXY",
              title: "Proxy manager",
              href: "/ProxyManager",
              permissoes: [1],
            },
            {
              key: "ADMINISTRACAO_BI",
              title: "BI",
              href: "/BI",
              permissoes: [1],
            },
            {
              key: "ADMINISTRACAO_PROMOCOES",
              title: "Promoções",
              href: "/Promocoes",
              permissoes: [1],
            },
            {
              key: "ADMINISTRACAO_BLOG",
              title: "Blog",
              href: "/BlogTrivelo",
              permissoes: [1],
            },
          ],
          permissoes: [1],
        },
        {
          key: "CARTEIRA_MILHAS",
          title: 'Carteira de milhas',
          selected: false,
          icon: 'wallet',
          href: '',
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
          permissaoAgencia: 6,
          subItens: [
            {
              key: "CARTEIRA_MILHAS_CARTOES",
              title: 'Cartões',
              href: '/Cartoes/ListaCartoes',
              permissoes: [1, 3],
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
              permissaoAgencia: 6,
            },
            {
              key: "CARTEIRA_MILHAS_RELATORIOS",
              title: 'Relatórios',
              href: '/Cartoes/Relatorios',
              permissoes: [1, 3],
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
              permissaoAgencia: 6,
            },
            {
              key: "CARTEIRA_MILHAS_CONFIGURACOES",
              title: 'Configurações',
              href: '/Cartoes/Configuracoes',
              planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
              permissaoAgencia: 6,
              permissoes: [1]
            },
          ],
        },
        {
          key: "CANAIS_VENDA",
          category: 'Canais de venda',
          iconCategory: 'plus',
          fnClickIcon: this.ShowModalCanais,
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
        },
        {
          key: "LOJA_ONLINE",
          iconCategory: 'plus',
          fnClickIcon: this.ShowModalCanais,
          title: "Loja online",
          selected: false,
          icon: "chalkboard",
          href: "",
          subItens: [
            {
              key: "LOJA_ONLINE_TEMAS",
              title: "Temas",
              href: "/Site",
              permissoes: [1, 3, 6],
              planos: [0, 1, 3, 4, 6, 7, 8, 10],
            },
            {
              key: "LOJA_ONLINE_PAGINAS",
              title: "Páginas",
              href: "/Pages",
              permissoes: [1, 3, 6],
              planos: [0, 1, 4, 6, 7, 8, 10]
            },
            {
              key: "LOJA_ONLINE_POSTS",
              title: "Posts",
              href: "/Conteudos",
              permissoes: [1, 3, 6],
              planos: [0, 1, 3, 4, 6, 7, 8, 10]
            },
            {
              key: "LOJA_ONLINE_DEPOIMENTOS",
              title: "Depoimentos",
              href: "/Depoimentos",
              permissoes: [1, 3, 6],
              planos: [0, 1, 3, 4, 6, 7, 8, 10]
            },
          ],
          planos: [0, 1, 3, 4, 6, 7, 8, 9, 10],
          permissoes: [1, 3, 6],
        },
        {
          key: "MARKETPLACE",
          title: "Marketplace",
          selected: false,
          icon: "cart-plus",
          href: "/MarketPlace",
          permissoes: [1, 3, 8],
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
          hide: true,
          validarHide: false,
        },
        {
          key: "GOOGLETTD",
          title: "Google TTD",
          selected: false,
          icon: "brands/google",
          href: "/googlettd",
          permissoes: [1, 3, 8],
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
          hide: true,
          validarHide: false,
        },
        {
          key: "FORNECEDORES",
          category: 'Fornecedores',
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
        },
        {
          key: "AEREO",
          title: "Aéreo",
          selected: false,
          icon: "plane",
          permissoes: [1, 3],
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
          // permissaoAgencia: 0,
          href: "/Milhas",
          subItens: [
            {
              key: "AEREO_CONFIGURACOES",
              title: "Configurações",
              href: "/ConfiguracaoPesquisa",
              // permissaoAgencia: 0,
              planos: [0, 2, 4, 7, 8, 10],
              permissoes: [1, 3],
            },
            {
              key: "AEREO_MILHAS",
              title: "Milhas",
              href: "/ValoresCias",
              //permissaoAgencia: 0,
              planos: [0, 2, 4, 7, 8, 10],
              permissoes: [1, 3],
            },
            {
              key: "AEREO_RELATORIO",
              title: "Relatório",
              href: "/Reports/Air",
              //permissaoAgencia: 0,
              planos: [0, 2, 4, 7, 8, 10],
              permissoes: [1, 3],
            },
          ],
        },
        {
          key: "HOTEL",
          title: "Hotel",
          selected: false,
          icon: "hotel",
          href: "/hotelConfig",
          subItens: [
            {
              key: "HOTEL_CONFIGURACOES",
              title: "Configurações",
              href: "/hotelConfig",
              planos: [0, 2, 4, 7, 8, 10],
              permissoes: [1, 3],
            },
          ],
          permissoes: [1, 3],
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
        },
        {
          key: "EXPERIENCIA_MARKETPLACE",
          title: "Marketplace",
          selected: false,
          icon: "bus",
          href: "/ExperienciaMarketPlace",
          planos: [0, 1, 2, 3, 4, 6, 7, 8, 10],
          permissoes: [1, 3],
        }
      ],
      dataModal: [
        {
          key: "MARKETPLACE",
          title: "Marketplace Moblix",
          urlRedirect: '/marketplace',
          subtitle:
            "Venda suas experiências turísticas para milhares de Agências de Viagens Online que utilizam a plataforma Moblix.",
          image: require("@/assets/img/Logos/ota.jpg"),
          hide: false,
        },
        /*{
          title: "Trivelo",
          urlRedirect: '/trivelo',
          subtitle:
            "Trivelo é um marketplace disponível em mais de 30 países para venda de experiências turísticas e passagens aéreas.",
          image: require("@/assets/img/Logos/Trivelo.png"),
        },*/
        /*{
          title: "Bancorbrás",
          urlRedirect: '/bancorbras',
          subtitle:
            "Venda suas experiências turísticas para milhares de clientes da Bancorbrás, um dos clubes de turismo mais famosos do Brasil.",
          image: require("@/assets/img/Logos/bancorbras.png"),
        },*/
        {
          key: "GOOGLETTD",
          title: "Google Coisas Legais para Fazer",
          urlRedirect: '/googlettd',
          subtitle:
            "Venda suas experiências turísticas para milhares de clientes no Google TTD, o buscador mais usado no mundo.",
          image: require("@/assets/img/Logos/GoogleTTD.png"),
          hide: false,
        }
      ],
      showModalCanais: false,
      agenciaAtual: null
    }
  },
  created() {
    this.util = new UtilService(this)
    this.marketPlaceService = new MarketPlaceService(this);
    this.parceiroService = new ParceiroService(this)
    this.GetMarketplace()
    this.PegarPermissoesAgencia();
    this.agenciaAtual = this.parceiroService.PegarParceiroAtual();
  },
  methods: {
    GetMarketplace() {
      let parceiroAtual = this.pessoaLogada.Parceiro
      if (parceiroAtual) {
        this.marketPlaceService.Get({ GetMarketPlaceParceiroLogado: true }, this.cbVerificarPossuiMarketPlacesHabilitado, null);
      } else {
        setTimeout(() => {
          this.GetMarketplace()
        }, 300)
      }
    },
    getCanalHabilitado(json, id) {
      return json.filter(function (data) {
        return data.IdCanal === id && data.Habilitado === true
      });
    },
    getItem(key) {
      return this.MenuItens.find((item) => item.key === key);
    },
    getSubItem(key, parentKey) {
      let item = this.MenuItens.find((item) => item.key === parentKey);

      return item.subItens.find((item) => {
        item.key === key;
      });
    },
    getItemModal(key) {
      return this.dataModal.find((item) => item.key === key);
    },
    cbVerificarPossuiMarketPlacesHabilitado({ Data }) {
      let found = this.getCanalHabilitado(Data, 2);

      let item = this.getItem('MARKETPLACE');
      let itemModal = this.getItemModal('MARKETPLACE');

      if (typeof (found) !== 'undefined' && found.length > 0) {
        item.hide = false;
        itemModal.hide = true;
      } else {
        item.hide = true;
        itemModal.hide = false;
      }

      found = this.getCanalHabilitado(Data, 5);

      item = this.getItem('GOOGLETTD');
      itemModal = this.getItemModal('GOOGLETTD');

      if (typeof (found) !== 'undefined' && found.length > 0) {
        item.hide = false;
        itemModal.hide = true;
      } else {
        item.hide = false;
        itemModal.hide = true;
      }
    },
    ShowModalCanais() {
      this.parceiroService._GetAppMain().ShowModalMenuCanais(this.dataModal)
    },
    IsDisabled(item) {
      if (this.pessoaLogada.IdPermissao === 1) {
        return false;
      }

      if (typeof (item) !== 'undefined' && item !== null) {
        let validarDisabled = typeof (item.validarDisabled) !== 'undefined' && item.validarDisabled !== null ? item.validarDisabled : true;

        if (validarDisabled) {
          let planoValido = this.ValidarPlano(item.planos);
          let permissaoValida = this.UsuarioPossuiPermissao(item);
          let permissaoAgenciaValida = this.AgenciaPossuiPermissao(item);

          // console.log(`Verificando item: ${item.title}`);
          // console.log(`Plano válido: ${planoValido}`);
          // console.log(`Permissão de usuário válida: ${permissaoValida}`);
          // console.log(`Permissão de agência válida: ${permissaoAgenciaValida}`);

          // Exceção para "Carteira de Milhas" - precisa preencher os 3 requisitos
          if (item.key === 'CARTEIRA_MILHAS') {
            if (planoValido && permissaoValida && permissaoAgenciaValida) {
              return false; // Habilitado apenas se todos os requisitos forem verdadeiros
            }
            return true; // Caso contrário, desabilitado
          }

          // Verificar se está no período de avaliação e ajustar plano
          if (this.isPeriodoAvaliacao) {
            planoValido = this.ValidarPlanoGratis(item);
            console.log(`Plano após ajuste de período de avaliação: ${planoValido}`);
          }

          // Regra geral: se a permissão da agência for válida, o item é habilitado
          if (permissaoAgenciaValida) {
            return false; // Se a permissão da agência for válida, o item é habilitado
          }

          // Regra geral: se o plano e a permissão do usuário forem válidos, o item é habilitado
          if (planoValido && permissaoValida) {
            return false; // Habilitado se ambos forem verdadeiros
          }
        } else {
          return false; // Se validarDisabled for falso, o item é habilitado
        }

        return true; // Caso contrário, o item será desabilitado
      }
    },
    IsHide(item) {

      if ((item.key === "PROGRAMA_DE_PARCEIROS" || item.key === "SOBRE_PROGRAMA" || item.key === "DIVULGACAO") && this.pessoaLogada.Parceiro.IdPlano !== 9) {
        return true;
      }

      if (this.pessoaLogada.IdPermissao === 1) {
        return false;
      }

      if (typeof (item.planos) === 'undefined' || item.planos === null) {
        return true;
      }

      if (typeof (item) !== 'undefined' && item !== null) {

        let hide = typeof (item.hide) !== 'undefined' && item.hide !== null ? item.hide : false;

        let validarHide = typeof (item.validarHide) !== 'undefined' && item.validarHide !== null ? item.validarHide : true;

        if (validarHide) {
          let plano = this.ValidarPlano(item.planos);

          if (plano) {
            return false;
          }
        } else {
          return hide;
        }
      }

      return true;
    },
    DisableItem() {
      //Ordem de validação: Permissão do Usuário, Permissão de Plano, Permissão de Aplicativo, Validação de sub-itens

      return true;

      /*
      if ((item.href ?? '').toLowerCase() === '/marketplace') {
        return this.esconderMarketPlace;
      } else if((item.href ?? '').toLowerCase() === '/googlettd') {
        return this.esconderGoogleTTD;
      } else if (item.href === '/Agencia') {
        return false;
      } else if (item.permissoes && item.permissoes.length && item.permissoes.indexOf(this.idPermissao) === -1) {
        return false;
      } else if (item.planos && item.planos.length && item.planos.indexOf(this.idPlano) === -1 && !this.AgenciaPossuiPermissao(item) && !this.ValidarPlanoGratis(item)) {
        return false;
      } else if (item.subItens && item.subItens.length && !item.subItens.find(x => this.ValidarItem(x, item.title))) {
        return false;
      } else {
        return true;
      }*/
    },
    ValidarPlanoGratis(item) {
      // PLANOS
      // 0	Plano Profissional
      // 1	Plano Básico
      // 2	Plano API
      // 3	Plano Grátis
      // 4	Plano Avançado
      // 5	Plano Afiliado
      // 6  Básico Anual
      // 7	Profissional Anual
      // 8	Avançado Anual
      // Login se periodo avaliacao trocar plano para 4
      if (typeof (item) !== 'undefined' && item !== null) {
        let planos = [0, 1, 4, 6, 7, 8, 10]
        let result = this.isPeriodoAvaliacao && item.planos.filter(x => planos.includes(x)).length
        return result;
      } else {
        return false;
      }

    },
    ValidarPlano(planos) {
      planos = typeof (planos) !== 'undefined' && planos !== null ? planos : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      return planos.includes(this.idPlano);
    },
    VerificaSeExiste(left, right) {
      let count = left.filter(function (item) {
        return right.includes(item);
      }).length;

      return count > 0;
    },
    AgenciaPossuiPermissao(item) {
      if (typeof (item.permissaoAgencia) !== 'undefined' && item.permissaoAgencia !== null) {

        // Mostra todas as permissões da agência para inspeção
        //console.log('Permissões da agência:', JSON.stringify(this.permissoesAgencia, null, 2));

        // Verifica se a permissão necessária para o item está entre as permissões da agência
        const permissaoNecessaria = item.permissaoAgencia;
        //console.log('Permissão necessária:', permissaoNecessaria);

        const permissaoEncontrada = this.permissoesAgencia.includes(permissaoNecessaria);
        //console.log(`Permissão encontrada para o item ${item.title}:`, permissaoEncontrada);

        // Retorna true se a permissão necessária estiver presente nas permissões da agência
        return permissaoEncontrada;
      } else {
        return null;
      }
    },
    UsuarioPossuiPermissao(item) {
      if (typeof (item.permissoes) !== 'undefined' && item.permissoes !== null) {
        if (typeof (this.idPermissao) !== 'undefined' && this.idPermissao !== null) {
          return item.permissoes.includes(this.idPermissao);
        } else {
          return false;
        }
      }
      else {
        return true;
      }
    },
    SelecionarMenu(item, subItem) {
      if (!subItem) {
        this.MenuItens.forEach((menu) => {
          if (item.title !== menu.title) {
            menu.selected = false
          }
        })
      }
      if (item) item.selected = !item.selected
    },
    AbrirPagina(item, subItem) {
      let url = item.href
      if (url.startsWith('http')) {
        window.open(url, '_blank')
      } else {
        this.SelecionarMenu(item, subItem)
        this.$router.push(url)
      }

      if (this.util.IsCompact())
        this.$parent.FecharMenu();
    },
    PegarPermissoesAgencia() {
      this.parceiroService.ListarPermissoes((res) => {
        let permissoes = []
        res.Data.forEach(r => permissoes.push(r.Permissao))
        this.permissoesAgencia = permissoes;
        //console.log("Permissões retornadas da API:", permissoes);
      })
    },
  },
  computed: {
    ShowMenu() {
      return this.idPermissao > -1 && this.idPlano > -1 && this.permissoesAgencia
    },
    idPermissao() {
      return this.pessoaLogada ? this.pessoaLogada.IdPermissao : -1;
    },
    idPlano() {
      return this.pessoaLogada && this.pessoaLogada.Parceiro ? this.pessoaLogada.Parceiro.IdPlano : -1;
    },
    isPeriodoAvaliacao() {
      return this.agenciaAtual ? this.agenciaAtual.PeriodoAvaliacao : false;
    }
  },
}
</script>
<style scoped>
.side-menu {
  overflow-y: auto;
  position: fixed;
  width: 250px;
  border-right: solid 1px #e6e6e6;
  transition: all .3s linear;
}

.side-menu-collapsed .side-menu {
  width: 64px !important;
}


::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #4d4d4d;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4f5866;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.barra {
  overflow-y: auto;
}

.menu-mobile {
  background: #eaeaea;
  height: 100vh;
  padding-top: 10px;
}

li span {
  font-weight: 500 !important;
}

.lb-category {
  margin: 0px;
  font-size: 12px;
  font-weight: 700;
  color: #6c757d;
  margin-left: 22px;
  margin-top: 18px;
  display: block;
  margin-bottom: 5px;
}

.icon-category {
  float: right;
  margin-right: 20px;
  font-size: 10px !important;
  cursor: pointer;
  margin-top: 4px;
}

.icon-category:hover {
  color: black;
}

.content-menu-item {
  padding: 0px 5px;
}

.content-sub-menu-item {
  margin-left: 12px;
  opacity: 0.8;
}

.content-sub-menu-item:last-child {
  margin-bottom: 10px;
}

.icon-menu {
  margin-left: 0px;
  margin-right: 15px;
  opacity: 0.85;
}

.el-menu-lateral {
  background: #ffffff00;
}

.container-logo {
  text-align: center;
  padding: 25px 0px 30px 0px;
}

.container-logo img {
  height: 28px !important;
}
</style>
