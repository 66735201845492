<template>
  <div class="divLembrarSenha">
    <div class="form-group row">
      <b-col>
        <div class="input-group" v-show="!sucessoLembrete">
          <label>Informe seu e-mail</label>
<!--          <el-input v-show="logarCpf" v-mask="'###.###.###-##'" type="tel" v-model="usuario"
                    @keyup.enter="Enviar()"
                    placeholder="Informe o seu CPF"></el-input>-->
          <el-input type="text" v-model="usuarioEmail" @keyup.enter.native="Enviar()"
                    placeholder="Informe o seu e-mail"></el-input>
        </div>
      </b-col>
    </div>
    <label-erro-login :msg="msgErro" :destacar="datacarErro"></label-erro-login>
    <b-row>
      <b-col>
        <div class="form-group m-t-20">
          <el-button @click="Voltar()" type="button" icon="el-icon-arrow-left">
            Voltar
          </el-button>
        </div>
      </b-col>
      <b-col>
        <div class="form-group text-right m-t-20">
          <el-button @click="Enviar()" :loading="carregando"
                     v-show="!sucessoLembrete"
                     type="primary">Enviar
            <i class="el-icon-check el-icon-right"></i>
          </el-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LoginService from '../../services/LoginService'
import LabelErroLogin from './LabelErroLogin'
import UtilService from "@/services/UtilService";

export default {
  name: 'LembrarSenha',
  data() {
    return {
      usuario: '',
      msgErro: null,
      datacarErro: false,
      sucessoLembrete: false,
      carregando: false,
      logarCpf: false,
      usuarioEmail: ''
    }
  },
  components: {
    'label-erro-login': LabelErroLogin
  },
  created() {
    this.loginService = new LoginService(this)
    this.util = new UtilService(this)
  },
  methods: {
    Voltar() {
      this.sucessoLembrete = false
      this.usuario = ''
      this.$emit('VoltarLogin')
    },
    Enviar() {
      let valido = this.Validar()
      if (valido) {
        this.carregando = true;
        this.loginService.LembrarSenha(this.logarCpf ? this.usuario : this.usuarioEmail, this.callbackLembrar, this.callbackLembrarErro)
      }
    },
    Validar() {
      this.msgErro = '';
      if (this.logarCpf) {
        if (!this.logarCpf || !this.util.IsCpf(this.usuario)) {
          this.msgErro = 'Informe um CPF válido'
        }
      } else if (!this.logarCpf) {
        if (!this.usuarioEmail || !this.util.EmailValido(this.usuarioEmail))
          this.msgErro = 'Informe um e-mail válido'
      }
      return this.msgErro.length === 0
    },
    callbackLembrar() {
      this.carregando = false;
      this.sucessoLembrete = true
      this.msgErro = false
      this.$message({
        message: 'Procedimento para alteração de senha enviado para o seu e-mail',
        type: 'success'
      })
      this.Voltar()
    }
    ,
    callbackLembrarErro(res) {
      this.carregando = false;
      console.log('Erro LEMBRAR SENHA')
      console.log(res)
      if (this.msgErro != null) {
        this.datacarErro = true
        setTimeout(() => {
          this.datacarErro = false
        }, 1000)
      }
      this.msgErro = res.Erro.Message
    }
  }
}
</script>

<style scoped>
.divLembrarSenha {
  margin-top: 30px;
}

.div-use-cpf {
  position: absolute;
  float: right;
  right: 0px;
}

.div-use-cpf label {
  margin-right: 10px;
  font-size: 12px;
}
</style>
