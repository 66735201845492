import { render, staticRenderFns } from "./LabelErroLogin.vue?vue&type=template&id=234933e8&scoped=true"
import script from "./LabelErroLogin.vue?vue&type=script&lang=js"
export * from "./LabelErroLogin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234933e8",
  null
  
)

export default component.exports