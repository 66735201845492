<template>
  <div class="form-horizontal margin-top-30">
    <transition :enter-active-class="animationDefault">
      <div class="form-group row" v-show="showItem1">
        <div class="col-12">
          <div class="input-group">
            <label class="label">E-mail</label>
            <el-input type="text" v-model="email" @keyup.enter.native="EfetuarLogin()"
              placeholder="Informe o seu e-mail"></el-input>
          </div>
        </div>
      </div>
    </transition>
    <transition :enter-active-class="animationDefault">
      <div class="form-group row" v-show="showItem2">
        <div class="col-12">
          <div class="input-group">
            <label class="label">Senha</label>
            <el-input v-model="senha" type="password" @keyup.enter.native="EfetuarLogin()" placeholder="Senha"></el-input>
          </div>
        </div>
      </div>
    </transition>
    <transition :enter-active-class="animationDefault">
      <div class="content-link" v-show="showItem4">
        <a href="javascript:;" @click="fnLembrarSenha()">
          <i class="fa fa-lock m-r-5"></i> Esqueceu sua senha?
        </a>
        <br />
      </div>
    </transition>
    <label-erro-login :msg="ErroMsgLogin" :destacar="destacarErroLogin"></label-erro-login>
    <transition :enter-active-class="animationDefault">
      <div class="form-group text-right m-t-20" v-show="showItem3">
        <el-button class="full-width" @click="EfetuarLogin()" :loading="carregando" type="primary">Entrar
          <i class="el-icon-check el-icon-right"></i>
        </el-button>
      </div>
    </transition>
    <div class="margin-top-15">
      <transition :enter-active-class="animationDefault" v-on:after-enter="adicionarScrollForm()">
        <div class="content-link" v-show="showItem5">
          Ainda não utiliza a Moblix? <a href="#/monte-sua-loja">Crie sua conta grátis!</a>
          <br />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import UtilService from '../../services/UtilService'
import LoginService from '../../services/LoginService'
import LabelErroLogin from './LabelErroLogin'

export default {
  name: 'DadosLogin',
  props: [],
  data() {
    return {
      cpf: '',
      email: '',
      senha: '',
      LembrarSenha: false,
      ErroMsgLogin: null,
      destacarErroLogin: false,
      animationDefault: 'animated bounceInUp',
      showItem1: false,
      showItem2: false,
      showItem3: false,
      showItem4: false,
      showItem5: false,
      logarCpf: false,
      carregando: false
    }
  },
  components: {
    'label-erro-login': LabelErroLogin
  },
  created() {
    this.util = new UtilService(this)
    this.loginService = new LoginService(this)
  },
  mounted() {
    setTimeout(() => {
      this.ListarItens()
    }, 500)
  },
  methods: {
    adicionarScrollForm() {
      let target = document.getElementsByClassName('col-form-login');
      if (target && target.length && target[0].style) {
        target[0].style.overflow = "auto";
      }
    },
    fnLembrarSenha() {
      this.$emit('exibirLembrarSenha')
    },
    ListarItens() {
      let ref = this
      for (let counter = 1; counter <= 5; counter++) {
        setTimeout(() => {
          ref['showItem' + counter] = true
        }, 150 * counter)
      }
    },
    EfetuarLogin() {
      let valido = this.Validar()
      if (valido) {
        this.carregando = true
        this.loginService.Login(this.email, this.senha, this.CallbackLogin, this.CallbackErroLogin)
      }
    },
    CallbackLogin(response) {
      this.carregando = false
      this.ErroMsgLogin = null
      if (response) {
        this.$emit('LoginSuccess')
      }
    },
    CallbackErroLogin(erro) {
      this.carregando = false
      if (this.ErroMsgLogin != null) {
        this.destacarErroLogin = true
        setTimeout(() => {
          this.destacarErroLogin = false
        }, 1000)
      }
      if (erro.response && erro.response.data && erro.response.data.error_description) {
        this.ErroMsgLogin = erro.response.data.error_description
      } else {
        this.ErroMsgLogin = erro.message
      }
    },
    Validar() {
      this.ErroMsgLogin = '';
      if (this.logarCpf) {
        if (!this.cpf || !this.util.IsCpf(this.cpf))
          this.ErroMsgLogin = 'Informe um CPF válido'
      } else if (!this.logarCpf) {
        if (!this.email || !this.util.EmailValido(this.email))
          this.ErroMsgLogin = 'Informe um e-mail válido'
      }
      return this.ErroMsgLogin.length === 0
    }
  }
}
</script>let iataOrigem = viagem.Trecho[0].IataOrigem

<style scoped>
.div-use-cpf {
  position: absolute;
  float: right;
  right: 0px;
}

.div-use-cpf label {
  margin-right: 10px;
  font-size: 12px;
}

.label {
  display: -webkit-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 15px;
  color: #343a40;
  padding-top: 15px;
}
</style>
