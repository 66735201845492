<template>
  <el-row class="container-row-table text-right">
    <el-button v-if="proxy.Id" class="margin-bottom-20" :icon="!editMode ? 'el-icon-edit' : 'el-icon-check'" size="mini"
               @click="ChangeEditMode">
      {{ !editMode ? 'Editar' : 'Concluir' }}
    </el-button>
    <div v-loading="loading">
      <el-table :data="ips" class="container-ips" v-if="!editMode">
        <el-table-column prop="Id" label="#" width="50"></el-table-column>
        <el-table-column prop="Config" label="Proxy"></el-table-column>
      </el-table>
      <el-input v-else v-model="textListIp" type="textarea" :rows="30"/>
    </div>
    <dialog-edit-subnet ref="dialogEditSubnet" @confirm="ResultConfirm"/>
  </el-row>
</template>

<script>
import ProxyManagerService from "@/services/ProxyManagerService";
import DialogEditSubnet from "@/components/Proxy/DialogEditSubnet.vue";

export default {
  name: "Subnet",
  components: {DialogEditSubnet},
  props: ['proxy'],
  data() {
    return {
      ips: [],
      editMode: false,
      textListIp: '',
      loading: false
    };
  },
  created() {
    this.Listar();
  },
  mounted() {
  },
  watch: {
    'proxy.Id': function () {
      this.Listar();
    }
  },
  methods: {
    ChangeEditMode() {
      this.editMode = !this.editMode
      if (this.editMode) {
        this.ConvertListIpsToText()
      } else {
        this.$refs.dialogEditSubnet.show = true;
      }
    },
    ResultConfirm(val) {
      if (val) {
        this.ConvertTextToListIps();
        this.Salvar();
      }
    },
    Listar() {
      this.loading = true
      this.ips = []
      if (!this.proxyManagerService)
        this.proxyManagerService = new ProxyManagerService(this)

      this.proxyManagerService.ListarSubnet(this.proxy.Id, this.Callback, this.callbackErro)
    },
    Callback(res) {
      this.loading = false
      this.ips = res.Data
    },
    callbackErro() {
      this.loading = false
      this.$message.error("Erro ao listar ips")
    },
    Salvar() {
      this.proxyManagerService.GravarSubnet(this.ips, this.ips, this.Listar)
    },
    ConvertListIpsToText() {
      this.textListIp = this.ips.map(ip => ip.Config).join('\n')
    },
    ConvertTextToListIps() {
      this.ips = this.textListIp.split('\n').map((config) => {
        return {
          Id: this.FindIdIp(config),
          IdProxy: this.proxy.Id,
          Config: config,
          Ativo: true
        }
      })
    },
    FindIdIp(config) {
      let retorno = this.ips.find(i => i.Config === config)
      return retorno ? retorno.Id : 0
    },
  },
  computed: {}
};
</script>

<style scoped>
.container-row-table {
  display: inline-block;
  width: 100%;
}

.container-ips {
  overflow: auto;
  max-height: 60vh;
  display: block;
}
</style>
