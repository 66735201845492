import GlobalResourceService from "../services/GlobalResourceService";

let DEFAULT_RESOURCE = null;
let TARGET_RESOURCE = null;

export default {
    defaultLan: "pt-BR",
    targetLan: "",
    install(Vue) {
        Vue.prototype.$Global = key => {
            return this.GetContent(key);
        };
        Vue.prototype.$StartLanguage = (callback, idioma) => {
            this.targetLan = idioma;
            this.GetLanguages(callback);
        };
    },
    GetContent(key) {
        if (TARGET_RESOURCE)
            return TARGET_RESOURCE.find(x => x.Key === key).Value;
        else if(DEFAULT_RESOURCE)
            return DEFAULT_RESOURCE.find(x => x.Key === key).Value;
    },
    GetLanguages(callback) {
        if (!this.globalService)
            this.globalService = new GlobalResourceService(this);

        if (this.targetLan)
            this.globalService.Get(this.targetLan, res => {
                if (res.HasResult) TARGET_RESOURCE = res.Data;
            });
        else
            this.globalService.Get(this.defaultLan, res => {
                if (res.HasResult) DEFAULT_RESOURCE = res.Data;
            });

        const watchResources = setInterval(() => {
            if (DEFAULT_RESOURCE || TARGET_RESOURCE) {
                clearInterval(watchResources);
                callback();
            }
        }, 100);
    },
    IsDone() {
        return DEFAULT_RESOURCE && <DEFAULT_RESOURCE className="len"></DEFAULT_RESOURCE>
    }
};