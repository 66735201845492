<template>
  <transition enter-active-class="animated bounceIn">
    <el-alert show-icon class="margin-top-10" :title="msg" type="warning" :closable="false" v-show="msg">
    </el-alert>
  </transition>
</template>

<script>
  export default {
    name: 'LabelErroLogin',
    props: ['msg', 'destacar']
  }
</script>

<style scoped>

</style>
