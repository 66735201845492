<template>
  <el-row class="navTop navHeader" v-bind:class="{
    'header-small': $screen.xs || $screen.sm,
    'margin-bottom-10': !$screen.xs && !$screen.sm,
  }">
    <el-col :xs="18" :span="16">
      <div class="container-button" v-if="!hideBack">
        <el-button type="info" class="btn-back" plain icon="el-icon-back" v-on:click="fnClose(false)">
        </el-button>
      </div>
      <div :class="{ 'container-label': !hideBack }">
        <span class="spn-title" v-if="label" v-bind:class="{ 'margin-top-10': !subLabel }">{{ label }}</span>
        <span class="spn-subtitle" v-if="subLabel">{{ subLabel }}</span>
      </div>
    </el-col>
    <el-col :xs="6" :span="8">
      <div class="text-right margin-top-10" v-if="!hideDefaultButtons">
        <div v-if="!$screen.xs && !$screen.sm">
          <el-button size="medium" icon="el-icon-close" @click="fnClose(true)">Sair
          </el-button>
          <el-button size="medium" type="primary" icon="el-icon-check" @click="$emit('guardar')">Salvar
          </el-button>
        </div>
        <el-dropdown class="margin-bottom-10" @command="handleCommand" trigger="click" v-else>
          <el-button size="small" type="primary" icon="el-icon-more-outline">
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="true">Salvar</el-dropdown-item>
            <el-dropdown-item :command="false">Descartar</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-else-if="lItemsDropdown && lItemsDropdown.length">
        <dropdown-header-modal :key="$route.fullPath" :l-dropdown="lItemsDropdown" :msg-loading="msgLoadingDropdown" />
      </div>
      <div v-else class="right-slot div-slot">
        <slot></slot>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import DropdownHeaderModal from "@/components/Shared/HeaderModal/DropdownHeaderModal.vue";

export default {
  name: "ContentHeaderModal.vue",
  components: { DropdownHeaderModal },
  props: [
    "fnClose",
    "label",
    "subLabel",
    "size",
    "hideDefaultButtons",
    'hideBack',
    'lItemsDropdown',
    'msgLoadingDropdown'
  ],
  data() {
    return {
      labelTemp: 'Adicionar'
    }
  },
  created() {
  },
  methods: {
    handleCommand(command) {
      if (command) {
        this.$emit('guardar')
      } else {
        this.fnClose(true);
        this.$emit('updateCloseStatus', true);
      }
    }
  }
}
</script>


<style scoped>
.navHeader {
  height: 55px;
  font-size: 1.2rem;
  color: white;
  padding: 0px 0px;
}

.navTop i {
  font-size: 25px;
  background: #ffffff17;
  height: 100%;
  padding: 20px 0px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  color: black;
}

.right-slot {
  cursor: pointer;
}

.div-slot {
  height: 44px;
  padding: 5px 0px;
  border-radius: 22px;
  width: 100%;
  text-align: right;
}

.spn-title {
  color: var(--text-color-black);
  font-size: 16px;
  font-weight: bold;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  max-width: 90%;
}

.spn-subtitle {
  color: var(--info-gray);
  font-size: 14px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  max-width: 90%;
}

.btn-back {
  min-width: 35px;
  color: var(--text-color-black);
  font-size: 22px;
  margin-left: 0px;
  position: relative;
  top: 5px;
}

@media only screen and (max-width: 992px) {
  .spn-title {
    font-size: 14px;
  }

  .spn-subtitle {
    font-size: 11px;
  }
}

.container-button {
  position: absolute;
}

.container-label {
  margin-left: 80px;
}
</style>
